import { combineReducers } from 'redux';

import announcementReducer from './announcementReducer';
import articleTypesReducer from './articleTypesReducer';
import articlesReducer from './articlesReducer';
import bankingTipsReducer from './bankingTipsReducer';
import emailSubscriptionsReducer from './emailSubscriptionsReducer';
import campaignsReducer from './campaignsReducer';
import ceoReducer from './ceoReducer';
import contentReducer from './contentReducer';
import coordinatesReducer from './coordinatesReducer';
import createAccountFormReducer from './createAccountFormReducer';
import customerStoriesReducer from './customerStoriesReducer';
import customerSupportReducer from './customerSupportReducer';
import eventsReducer from './eventsReducer';
import generalArticlesReducer from './generalArticlesReducer';
import globalSearchReducer from './globalSearchReducer';
import homepageReducer from './homepageReducer';
import jobsReducer from './jobsReducer';
import manageUsersReducer from './manageUsersReducer';
import memberInstitutionsReducer from './membersInstitutionsReducer';
import navReducer from './navReducer';
import newsArticlesReducer from './newsArticlesReducer';
import feedsReducer from './feedsReducer';
import sectorNewsReducer from './sectorNewsReducer';
import staffReducer from './staffReducer';
import pageReducer from './pageReducer';
import subscriptionReducer from './subscriptionReducer';
import supportReducer from './supportReducer';
import trainingCartReducer from './trainingCartReducer';
import trainingReducer from './trainingReducer';
import userReducer from './userReducer';
import portalAnnouncementReducer from './portalAnnouncementReducer';
import headerReducer from './headerReducer';
import footerReducer from './footerReducer';

const rootReducer = combineReducers({
  announcementReducer,
  articleTypesReducer,
  articlesReducer,
  bankingTipsReducer,
  campaignsReducer,
  emailSubscriptionsReducer,
  ceoReducer,
  contentReducer,
  coordinatesReducer,
  createAccountFormReducer,
  customerStoriesReducer,
  customerSupportReducer,
  eventsReducer,
  generalArticlesReducer,
  globalSearchReducer,
  homepageReducer,
  jobsReducer,
  manageUsersReducer,
  memberInstitutionsReducer,
  navReducer,
  newsArticlesReducer,
  feedsReducer,
  sectorNewsReducer,
  staffReducer,
  pageReducer,
  subscriptionReducer,
  supportReducer,
  trainingCartReducer,
  trainingReducer,
  userReducer,
  portalAnnouncementReducer,
  headerReducer,
  footerReducer
});

export default rootReducer;
