import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { isEmpty } from 'lodash';
import useMedia from 'use-media';
import { requestTrainingModuleById } from '@/store/actions';
import { selectTrainingModuleById } from '@/store/selectors';
import { PortalLayout, PortalPageTitle, ContentSection, Loading } from '@/components/Portal';
import { ActionCard, TrainingCard } from '@/components/Portal/Card';
import { TrainingModuleMenu } from '@/components/Portal/Training';

/**
 * Converts JSON Stings into objects
 *
 * @param {object} event
 * @returns
 */
 const transform = (trainingModule) => {
	return isEmpty(trainingModule)
	? trainingModule
	:	{
		...trainingModule,
		key_contacts: JSON.parse(trainingModule.key_contacts),
	};
}

const Card = ({trainingModule}) => {
  return (
  <TrainingCard
      description={trainingModule.description}
      id={trainingModule.id}
      key={trainingModule.id}
      pricing={trainingModule.current_users_training_price}
      purchaseStatus={trainingModule.purchaseStatus}
      slug={trainingModule.slug}
      title={trainingModule.name}
      updatedOn={trainingModule.course_updated}
      installation_instructions_data={trainingModule.installation_instructions_data}
      training_downloads_data={trainingModule.training_downloads_data}
    />
  );
}

const InstallationInstructions = ({instructions}) => {
	return isEmpty(instructions)
	? null
	: <ContentSection 
		headingType='h2'
		heading='Installation Instructions'
		description='Please download and read the instructions on how to use and install your training modules.'
	>
		{instructions.map(({installation_instructions}, index) => {
			return <ActionCard 
				key={index}
				action='download'
				title='Installation Instructions'
				link={installation_instructions.url}
				value={installation_instructions.size}
			/>
		})}
	</ContentSection>
}

const TrainingSection = ({trainingModule}) => {
  const isMobile = useMedia('(max-width: 1023px)');

  return (
  <>
    {isMobile && <Card trainingModule={trainingModule} />}

    <ContentSection 
      headingType='h2'
      heading={trainingModule?.name}
      description={trainingModule?.description}
    />

    <div
      className="article-content"
      dangerouslySetInnerHTML={{ __html: trainingModule?.content }}
    />

    <Card trainingModule={trainingModule} />

    <InstallationInstructions 
      instructions={trainingModule.installation_instructions_data}
    />

  </>
  );
}

export default function TrainingModule() {
  const params = useParams();
  const dispatch = useDispatch();
  const trainingID = params.id;
  const training = useSelector(selectTrainingModuleById(trainingID));
  const trainingModule = transform(training.module);

  const [pageLoading, setPageLoading] = useState(true);
  const [pageHeading, setPageHeading] = useState('');

  useEffect(() => {
    setPageLoading(training.loading);
  }, [training.loading]);

  useEffect(() => {
    if(!isEmpty(trainingModule)){
      setPageHeading(trainingModule?.name);
    }
  }, [trainingModule]);

  useEffect(() => {
    if (isEmpty(trainingModule) && !isEmpty(trainingID)) {
      dispatch(
        requestTrainingModuleById({ id: trainingID })
      )
    }
  }, [trainingID]);

	return (
		<PortalLayout>
			<PortalPageTitle heading={pageHeading} secondary />
			<TrainingModuleMenu trainingModule={trainingModule} mobile/>

			<div className="container">
				<div className="row">
					<TrainingModuleMenu trainingModule={trainingModule} desktop/>

          <div className="col-xs-12 col-sm-8">
            <div className="article-wrapper">
              {pageLoading
              ? <Loading />
              : !!TrainingModule && <TrainingSection trainingModule={trainingModule}/> 
              }
            </div>
          </div>
        </div>
      </div>
    </PortalLayout>
  );
}
