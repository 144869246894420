import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { format } from 'date-fns';
import { first } from 'lodash';
// Store
import { useDispatch } from 'react-redux';
import { trainingCartItemAdd } from '@/store/actions';
// Utils
import { PORTAL_PATHS } from '@/config';
import { truncateText, utcToLocal } from '@/utils';
// Components
import { Card } from '@/components/Portal/Card';
import { DownloadModal } from '@/components/Portal/Training';
import { Button } from '@/components';
import {
	iconArrowThin,
	iconPortalCalendar,
	iconPortalMoney,
	iconPortalLayers,
	iconPortalDownload
} from '@/images/icons';

TrainingCard.propTypes = {
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  size: PropTypes.oneOf(['small', 'large']),
  status: PropTypes.oneOf(['requested', 'in-cart', 'ready-to-download']),
  category: PropTypes.string,
  title: PropTypes.string,
  pricing: PropTypes.number,
  updatedOn: PropTypes.string,
  description: PropTypes.string,
  downloads: PropTypes.object,
  slug: PropTypes.string,
  readMore: PropTypes.bool,
  installation_instructions_data: PropTypes.array,
  training_downloads_data: PropTypes.array
};

const validStatus = {
  'requested': {
    type: 'training-requested',
    label: 'requested',
    buttonLabel: ''
  },
  'in-cart': {
    type: 'training-in-cart',
    label: 'in cart',
    buttonLabel: 'View in cart'
  },
  'ready-to-download': {
    type: 'training-ready-to-download',
    label: 'ready to download',
    buttonLabel: 'Download'
  }
};

const FIRST_TAG_REGEX = /<(\w+)>(.*?)<\/\1>/;

export default function TrainingCard({
  id,
  size = 'large',
  category = 'Training',
  purchaseStatus,
  title,
  pricing,
  updatedOn,
  description,
  readMore = false,
  installation_instructions_data,
  training_downloads_data
}) {
  const cardLink = `${PORTAL_PATHS.TRAINING}/module/${id}`;

  const dispatch = useDispatch();
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const trainingStatus = Object.prototype.hasOwnProperty.call(validStatus, purchaseStatus)
    ? validStatus[purchaseStatus]
    : null;

	const dateUpdated = !!updatedOn
		? format(utcToLocal(updatedOn), 'd LLLL yyyy')
		: updatedOn;

  const firstParagraphText = !!description
    ? truncateText(first(description.match(FIRST_TAG_REGEX)).replace(/<[^>]+>/g, ''))
    : '';

  pricing = !pricing 
    ? 'Price On Application' 
    : pricing 

	const items = [
		{ icon: iconPortalCalendar, details: `UPDATED: ${dateUpdated}` },
		{ icon: iconPortalMoney, details: `${pricing}` }
	];
   
  // Download
  const hideModal = () => {
    setModalIsOpen(false);
  }
  
  const onClickDownloadTraining = () => (ev) => {
    ev.preventDefault();
    setModalIsOpen(true)
  }
  
  const ButtonDownload = () => {
    const icon = iconPortalDownload;
    const label = 'Download';
  
    return (
      <Button
        variant="btn-fill"
        icon={icon} 
        onClick={onClickDownloadTraining()}
      >
        {label}
      </Button>
    );
  } 

  // Cart
  const onClickAddToCart = () => (ev) => {
    ev.preventDefault();
    dispatch(trainingCartItemAdd({
      id: id,
      category: category,
      title: title,
      price: pricing
    }))
  }

  const ButtonAddToCart = () => {	
    const icon = iconArrowThin;
    const label = 'Add To Cart';
  
    return (
      <Button
        variant="btn-fill"
        icon={icon} 
        onClick={onClickAddToCart()}
      >
        {label}
      </Button>
    );
  }

  const ButtonViewInCart = () => {	
    const icon = iconArrowThin;
    const label = 'View in Cart';
    const link = `${PORTAL_PATHS.TRAINING}/my/cart`

    return (
      <Link className="btn btn-fill" to={link} >
        {label} <span className="icon midnight">{icon}</span>
      </Link>
    );
  }
  
  // Read more
  const ButtonReadMore = () => {
    const icon = iconArrowThin;
    const label = 'Read More';
    const link = cardLink

    return (
      <Link className="btn btn-text" to={link} >
        {label} <span className="icon midnight">{icon}</span>
      </Link>
    );
  }
  
  const Actions = () => {
    return <>
      {purchaseStatus === 'ready-to-download' && <ButtonDownload /> }
      {purchaseStatus === 'ready-to-download' && <DownloadModal
        icon={iconPortalLayers}
        title={title}
        subHeading={category}
        showModal={modalIsOpen}
        onClose={hideModal}
        installation={installation_instructions_data}
        downloads={training_downloads_data}
      /> }
      {purchaseStatus === 'in-cart' && <ButtonViewInCart />}
      {purchaseStatus == null && <ButtonAddToCart /> }
      {readMore && <ButtonReadMore /> }
    </>
  }

  return (
    <Card
      key={id}
      link={cardLink}
      size={size}
      title={title}
      status={trainingStatus}
      type={category}
      icon={iconPortalLayers}
      items={items}
      content={firstParagraphText}
      actions={<Actions/>}
    />
  );
}
