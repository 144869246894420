import { PORTAL_PATHS } from '@/config';
import useAnalyticEventsTracking from '@/hooks/useAnalyticEventsTracking';
import { ResetPassword } from '@/pages';
import {
  CEOsDesk,
  EventDetails,
  Events,
  Feeds,
  GlobalSearchResults,
  MyAccount,
  Newsroom,
  NewsroomArticle,
  Onboarding,
  Portal404,
  PortalHome,
  StaffDirectory,
  StaticPage,
  Support,
  Training,
  TrainingModule,
  Vacancies,
  VacancyDetails
} from '@/pages/Portal';
import { checkToken } from '@/store/actions';
import { getUser, getUserToken } from '@/store/selectors';
import { isEmpty } from 'lodash';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, Route, Switch, useHistory, useLocation } from 'react-router-dom';

export default function PortalRoutes() {
  const dispatch = useDispatch();
  const user = useSelector(getUser);
  const userToken = useSelector(getUserToken);
  const location = useLocation();
  const history = useHistory();
  let searchParams = new URLSearchParams(location.search);

  const trackEvent = useAnalyticEventsTracking();

  useEffect(() => {
    trackEvent({ type: 'page_view' });
  }, [location?.pathname]);

  //concat queries
  const addQuery = (key, value) => {
    searchParams.set(key, value);
    history.push({
      pathname: `${PORTAL_PATHS.onboarding.signIn}`,
      search: searchParams.toString()
    });
  };
  React.useEffect(() => {
    if (!isEmpty(userToken)) {
      dispatch(checkToken());
    }
  }, [userToken]);
  React.useEffect(() => {
    if (!userToken) {
      if (
        !location.pathname.includes(PORTAL_PATHS.onboarding.index) &&
        !location.pathname.includes('/reset-password')
      ) {
        addQuery('backUrl', location.pathname);
      }
    }
  }, [userToken]);

  return (
    <Switch>
      {/* Onboarding */}
      <Route path="/onboarding/reset-password/:token" component={ResetPassword} />
      <Redirect from="/email/verify/*" to="/onboarding/create-account/email-verified" />
      <Route
        exact
        path="/reset-password/:token"
        render={({ match }) => (
          <Redirect to={`/onboarding/reset-password/${match.params.token}`} />
        )}
      />
      <Route path={PORTAL_PATHS.onboarding.index} component={Onboarding} />

      <Route exact path="/404" component={Portal404} />

      {/* My Account */}
      <Route component={MyAccount} path={`${PORTAL_PATHS.MYACCOUNT}`} />

      {/* Resources and Services - Training */}
      <Redirect
        exact
        from={`${PORTAL_PATHS.TRAINING}`}
        to={`${PORTAL_PATHS.TRAINING}/my/available`}
      />
      <Route component={TrainingModule} path={`${PORTAL_PATHS.TRAINING}/module/:id`} />
      <Route component={Training} path={`${PORTAL_PATHS.TRAINING}`} />

      {
        user.training_only && <Redirect to={`${PORTAL_PATHS.TRAINING}`} />
        /**
         * A user with training_only should NOT be able to access routes past this point
         */
      }

      {/* Home */}
      <Route component={PortalHome} exact path={['/', '/home']} />
      <Route component={CEOsDesk} path="/ceos-desk/:slug" />
      <Route component={GlobalSearchResults} path={`${PORTAL_PATHS.GLOABL_SEARCH}`} />

      {/* About */}
      <Route component={StaffDirectory} path={`${PORTAL_PATHS.ABOUT}/staff-directory`} />

      {/* Suport */}
      <Route component={Support} path={`${PORTAL_PATHS.SUPPORT}`} />

      {/* Newsroom */}
      <Route component={NewsroomArticle} path={`${PORTAL_PATHS.NEWSROOM}/:slug`} />
      <Route component={Newsroom} path={`${PORTAL_PATHS.NEWSROOM}`} />

      <Route component={Feeds} path={`${PORTAL_PATHS.FEEDS}`} />

      {/* Events */}
      <Redirect
        exact
        from={`${PORTAL_PATHS.EVENTS}`}
        to={`${PORTAL_PATHS.EVENTS}/upcoming`}
      />
      <Route component={EventDetails} path={`${PORTAL_PATHS.EVENTS}/event/:id`} />
      <Route component={Events} path={`${PORTAL_PATHS.EVENTS}/upcoming`} />
      <Route component={Events} path={`${PORTAL_PATHS.EVENTS}/past`} />

      {/* Resources and Services - Vacancies */}
      <Redirect
        exact
        from={`${PORTAL_PATHS.VACANCIES}`}
        to={`${PORTAL_PATHS.VACANCIES}/view-vacancies`}
      />
      <Route component={VacancyDetails} path={`${PORTAL_PATHS.VACANCIES}/job/:id`} />
      <Route component={Vacancies} path={`${PORTAL_PATHS.VACANCIES}`} />

      <Route
        component={StaticPage}
        path={[
          `${PORTAL_PATHS.ABOUT}/*/:slug`,
          `${PORTAL_PATHS.ABOUT}/:slug`,
          `${PORTAL_PATHS.RESOURCESSERVICES}/*/:slug`,
          `${PORTAL_PATHS.RESOURCESSERVICES}/:slug`
        ]}
      />
    </Switch>
  );
}
