import { all } from 'redux-saga/effects';
import watchRequestArticleTypes from './articleTypesSaga';
import watchRequestBankingTips from './bankingTipsSaga';
import watchRequestCoordinates from './coordinatesSaga';
import watchRequestCampaigns from './campaignsSaga';
import watchRequestContent from './contentSaga';
import watchRequestCustomerStories from './customerStoriesSaga';
import watchRequestCustomerSupport from './customerSupportSaga';
import watchRequestGeneralArticles from './generalArticlesSaga';
import watchRequestSectorNews from './sectorNewsSaga';
import watchRequestSubscription from './subscriptionSaga';
import announcementSaga from './announcementSaga';
import portalAnnouncementSaga from './portalAnnouncementSaga';
import articlesSaga from './articlesSaga';
import ceoSaga from './ceoSaga';
import emailSubscriptionsSaga from './emailSubscriptionsSaga';
import eventsSaga from './eventsSaga';
import globalSearchSaga from './globalSearchSaga';
import homepageSaga from './homepageSaga';
import jobsSaga from './jobsSaga';
import manageUsers from './manageUsersSaga';
import memberInstitutionsSaga from './membersInstitutionsSaga';
import navSaga from './navSaga';
import newsArticlesSaga from './newsArticlesSaga';
import feedsSaga from './feedsSaga';
import pageSaga from './pageSaga';
import staffSaga from './staffSaga';
import supportSaga from './supportSaga';
import trainingCartSaga from './trainingCartSaga';
import trainingSaga from './trainingSaga';
import userSaga from './userSaga';
import headerSaga from './headerSaga';
import footerSaga from './footerSaga';
import analyticEventsSaga from './analyticEventsSaga'

//3.Root saga
//This is the single entry to all sagas => Combines all "watchers"
export default function* rootSaga() {
  yield all([
    watchRequestArticleTypes(),
    watchRequestBankingTips(),
    watchRequestCoordinates(),
    watchRequestCampaigns(),
    watchRequestContent(),
    watchRequestCustomerStories(),
    watchRequestCustomerSupport(),
    watchRequestGeneralArticles(),
    watchRequestSectorNews(),
    watchRequestSubscription(),
    ...announcementSaga,
    ...portalAnnouncementSaga,
    ...articlesSaga,
    ...ceoSaga,
    ...emailSubscriptionsSaga,
    ...eventsSaga,
    ...globalSearchSaga,
    ...homepageSaga,
    ...jobsSaga,
    ...manageUsers,
    ...memberInstitutionsSaga,
    ...navSaga,
    ...newsArticlesSaga,
    ...feedsSaga,
    ...pageSaga,
    ...staffSaga,
    ...supportSaga,
    ...trainingCartSaga,
    ...trainingSaga,
    ...userSaga,
    ...headerSaga,
    ...footerSaga,
    ...analyticEventsSaga
  ]);
}
