import PropTypes from 'prop-types';
import React from 'react';
import {
	AccordionItem,
  CtaCard,
  TableBlock,
  TextSection,
} from '@/components';
import { ActionCard, ContactCard } from '@/components/Portal/Card';


CMSPageContent.propTypes = {
  content: PropTypes.array
};

export default function CMSPageContent({content}) {
	return content.map( ({layout, attributes}, index ) => 
		<Content key={index} layout={layout} attributes={attributes} />
	)
}

const Content = ({layout, attributes, id}) => {
	switch (layout) {
		case 'h2_section':
		case 'h3_section':
			return (
				<TextSection
					key={id}
					H2Title={layout === 'h2_section' ? attributes['title'] : null}
					H3Title={layout === 'h3_section' ? attributes['title'] : null}
					content={attributes['paragraph']}
				/>
			);

		case 'download_section':
			return (
				<div key={id} className="content-section">
					{attributes['downloads_buttons'].map(({attributes}, index) => (
						<ActionCard
							key={index}
							title={attributes.download_title}
							action='download' 
							value={attributes.download_file_size}
							link={attributes.download_url}
						/>	
					))}
				</div>
			);

		case 'faq':
			return (
				<div key={id} className="accordion-section">
					{attributes['accordion'].map(({attributes}, index) => (
						<AccordionItem
							key={index}
							title={attributes.title}
							content={attributes.content}
						/>
					))}
				</div>
			);

		case 'cta':
			return (
				<CtaCard
					key={id}
					title={attributes['title']}
					copy={attributes['copy']}
					buttonText={attributes['button_text']}
					buttonUrl={attributes['button_url']}
				/>
			);

		case 'key_contacts':
			return (
				<ContactCard
					key={id}
					name={attributes['contact_name']}
					title={attributes['contact_title']}
					email={attributes['contact_email']}
					phone={attributes['contact_phone']}
				/>
			);

		case 'table':
			return <TableBlock key={id} data={attributes['table']} />;

		default:
			return null;
	}
}