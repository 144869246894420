import React from 'react';

const iconAccordionOpen = (
  <svg
    className="icon-accordion-open"
    fill="none"
    height="24"
    viewBox="0 0 24 24"
    width="24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M12 2V22" stroke="#006282" strokeWidth="2" />

    <path d="M22 12L2 12" stroke="#006282" strokeWidth="2" />
  </svg>
);

const iconAccordionClose = (
  <svg
    className="icon-accordion-close"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M22 12L2 12" stroke="#006282" strokeWidth="2" />
  </svg>
);

const iconArrowThin = (
  <svg
    className="icon-arrow-thin-right"
    fill="none"
    height="24"
    viewBox="0 0 24 24"
    width="24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.2929 14.2929L16 15L19 12L16 9L15.2929 9.70711L17.0858 11.5L5 11.5V12.5L17.0857 12.5L15.2929 14.2929Z"
      fill="#001B31"
    />
  </svg>
);

const iconArrowThinLeft = (
  <svg
    className="icon-arrow-thin-left"
    fill="none"
    height="24"
    viewBox="0 0 24 24"
    width="24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.70708 14.2929L7.99998 15L5 12L8 9L8.70711 9.70711L6.91416 11.5L19 11.5V12.5L6.91426 12.5L8.70708 14.2929Z"
      fill="#001B31"
    />
  </svg>
);

const iconArrowThinLongLeft = (
  <svg
    className="icon-arrow-thin-long-left"
    width="44"
    height="44"
    viewBox="0 0 44 44"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.963 26.2036L14.6667 27.5L9.16671 22L14.6667 16.5L15.9631 17.7964L12.676 21.0834L34.8334 21.0834L34.8334 22.9168L12.6762 22.9168L15.963 26.2036Z"
      fill="#006282"
    />
  </svg>
);

const iconArrowThinLongRight = (
  <svg
    className="icon-arrow-thin-long-right"
    width="44"
    height="44"
    viewBox="0 0 44 44"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M28.0371 17.7964L29.3334 16.5L34.8334 22L29.3334 27.5L28.037 26.2036L31.3241 22.9166L9.16672 22.9166L9.16672 21.0832L31.3239 21.0832L28.0371 17.7964Z"
      fill="#006282"
    />
  </svg>
);

const iconArrowThinLongDown = (
  <svg
    className="icon-arrow-thin-long-down"
    width="44"
    height="44"
    viewBox="0 0 44 44"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M26.2037 28.037L27.5001 29.3334L22.0001 34.8333L16.5001 29.3333L17.7964 28.037L21.0835 31.324L21.0835 9.16666L22.9168 9.16666L22.9168 31.3239L26.2037 28.037Z"
      fill="#006282"
    />
  </svg>
);

const iconBarHorizontal = (
  <svg
    className="icon-bar-horizontal"
    width="56"
    height="6"
    viewBox="0 0 56 6"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M0.0136719 3L55.5514 3" stroke="#B9B9BA" strokeWidth="5" />
  </svg>
);

const iconChevronDown = (
  <svg
    className="icon-chevron-down"
    width="14"
    height="8"
    viewBox="0 0 14 8"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.989502 1.70181L6.99991 7.71222L13.0103 1.70181L11.5961 0.287598L6.99991 4.88379L2.40372 0.287598L0.989502 1.70181Z"
      fill="#22B7C4"
    />
  </svg>
);

const iconChevronLeft = (
  <svg
    className="icon-chevron-left"
    width="8"
    height="14"
    viewBox="0 0 8 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.29819 0.989502L0.28778 6.99991L6.29819 13.0103L7.7124 11.5961L3.11621 6.99991L7.7124 2.40372L6.29819 0.989502Z"
      fill="#E9E9EA"
    />
  </svg>
);

const iconChevronRight = (
  <svg
    className="icon-chevron-right"
    width="8"
    height="14"
    viewBox="0 0 8 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.70193 13.0105L7.71234 7.00009L1.70193 0.989683L0.28772 2.4039L4.88391 7.00009L0.28772 11.5963L1.70193 13.0105Z"
      fill="#22B7C4"
    />
  </svg>
);

const iconCross = (
  <svg
    className="icon-cross"
    width="26"
    height="26"
    viewBox="0 0 26 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M21.9375 4.0625L4.0625 21.9375"
      stroke="#22B7C4"
      strokeWidth="3"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M4.0625 4.0625L21.9375 21.9375"
      stroke="#006282"
      strokeWidth="3"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const iconCrossThin = (
  <svg
    className="icon-cross-thin"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M18.9375 1.0625L1.0625 18.9375"
      stroke="#22B7C4"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M1.0625 1.0625L18.9375 18.9375"
      stroke="#006282"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const iconDot = (
  <svg
    className="icon-dot"
    width="10"
    height="10"
    viewBox="0 0 10 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="5" cy="5" r="5" fill="#22B7C4" />
  </svg>
);

const iconElipse = (
  <svg
    className="icon-elipse"
    width="4"
    height="4"
    viewBox="0 0 4 4"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="2" cy="2" r="2" fill="#F5F5F5" />
  </svg>
);

const iconFacebook = (
  <svg
    className="icon-facebook"
    width="10"
    height="16"
    viewBox="0 0 10 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.02293 16L3 9H0V6H3V4C3 1.3008 4.67151 0 7.07938 0C8.23277 0 9.22406 0.08587 9.51294 0.12425V2.94507L7.84296 2.94583C6.53343 2.94583 6.27987 3.5681 6.27987 4.48124V6H10L9 9H6.27986V16H3.02293Z"
      fill="#22B7C4"
    />
  </svg>
);

const iconLinkedin = (
  <svg
    className="icon-linkedin"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.3 0H0.7C0.3 0 0 0.3 0 0.7V15.4C0 15.7 0.3 16 0.7 16H15.4C15.8 16 16.1 15.7 16.1 15.3V0.7C16 0.3 15.7 0 15.3 0ZM4.7 13.6H2.4V6H4.8V13.6H4.7ZM3.6 5C2.8 5 2.2 4.3 2.2 3.6C2.2 2.8 2.8 2.2 3.6 2.2C4.4 2.2 5 2.8 5 3.6C4.9 4.3 4.3 5 3.6 5ZM13.6 13.6H11.2V9.9C11.2 9 11.2 7.9 10 7.9C8.8 7.9 8.6 8.9 8.6 9.9V13.7H6.2V6H8.5V7C8.8 6.4 9.6 5.8 10.7 5.8C13.1 5.8 13.5 7.4 13.5 9.4V13.6H13.6Z"
      fill="#22B7C4"
    />
  </svg>
);

const iconLocationSmall = (
  <svg
    className="icon-location-small"
    width="10"
    height="12"
    viewBox="0 0 10 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.9987 0C2.4187 0 0.332031 2.08667 0.332031 4.66667C0.332031 8.16667 4.9987 12 4.9987 12C4.9987 12 9.66537 8.16667 9.66537 4.66667C9.66537 2.08667 7.5787 0 4.9987 0ZM4.9987 6.66667C6.10327 6.66667 6.9987 5.77124 6.9987 4.66667C6.9987 3.5621 6.10327 2.66667 4.9987 2.66667C3.89413 2.66667 2.9987 3.5621 2.9987 4.66667C2.9987 5.77124 3.89413 6.66667 4.9987 6.66667Z"
      fill="#006282"
    />
  </svg>
);

const iconLocationAlt = (
  <svg
    className="icon-location-alt"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.287 6.51856C10.287 7.74586 9.26367 8.74078 8.0013 8.74078C6.73894 8.74078 5.71559 7.74586 5.71559 6.51856C5.71559 5.29126 6.73894 4.29634 8.0013 4.29634C9.26367 4.29634 10.287 5.29126 10.287 6.51856Z"
      fill="#2E3A59"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.66797 6.51856C2.66797 10.4074 8.0013 14.6667 8.0013 14.6667C8.0013 14.6667 13.3346 10.4074 13.3346 6.51856C13.3346 3.65189 10.9499 1.33337 8.0013 1.33337C5.05273 1.33337 2.66797 3.65189 2.66797 6.51856ZM4.19178 6.51856C4.19178 4.47009 5.89431 2.81486 8.0013 2.81486C10.1083 2.81486 11.8108 4.47009 11.8108 6.51856C11.8108 7.89685 10.8084 9.57754 9.49878 11.1009C8.96998 11.716 8.43805 12.2521 8.0013 12.6648C7.56455 12.2521 7.03262 11.716 6.50383 11.1009C5.19421 9.57754 4.19178 7.89685 4.19178 6.51856Z"
      fill="#2E3A59"
    />
  </svg>
);

const iconLoudSpeaker = (
  <svg
    className="icon-loud-speaker"
    width="100"
    height="100"
    viewBox="0 0 100 100"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M79.6475 29.6323L30.0137 42.3127V49.735V57.1631L79.6475 69.8436"
      stroke="#001B31"
      strokeWidth="1.75853"
      strokeMiterlimit="8"
    />
    <path
      d="M79.6484 49.7349V28.556C79.6484 27.1406 80.7948 26 82.2045 26C83.6199 26 84.7604 27.1464 84.7604 28.556V70.9196C84.7604 72.335 83.614 73.4756 82.2045 73.4756C80.7889 73.4756 79.6484 72.3291 79.6484 70.9196V62.5439"
      stroke="#001B31"
      strokeWidth="1.75853"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M20.918 58.4729L30.0132 57.0341V42.3124L20.918 40.8735"
      stroke="#001B31"
      strokeWidth="1.75853"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M20.9191 57.7831C20.9191 59.4207 19.5914 60.7426 17.9596 60.7426C16.3219 60.7426 15 59.4149 15 57.7831V41.5641C15 39.9264 16.3276 38.6045 17.9596 38.6045C19.5972 38.6045 20.9191 39.9322 20.9191 41.5641V45.4419"
      stroke="#001B31"
      strokeWidth="1.75853"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M40.6055 59.9995V69.3461C40.6055 71.0072 41.9508 72.3524 43.6118 72.3524H55.0991C56.7601 72.3524 58.1055 71.0072 58.1055 69.3461V64.7254"
      stroke="#001B31"
      strokeWidth="1.75853"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const iconMan = (
  <svg
    className="icon-man"
    width="100"
    height="100"
    viewBox="0 0 100 100"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M54.2798 57.8843L50.0745 62.0019L45.8691 57.8843"
      stroke="#001B31"
      strokeWidth="1.75853"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M47.9867 59.9609L43.8281 80.6368L50.0747 86.9361"
      stroke="#001B31"
      strokeWidth="1.75853"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M52.1564 59.9609L56.3208 80.6368L50.0742 86.9361"
      stroke="#001B31"
      strokeWidth="1.75853"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M61.5862 77.4254L78.1503 73.9922L75.9628 63.0313C75.4774 60.6157 74.1672 58.4165 72.2605 56.8373C71.0439 55.8371 69.8098 55.1937 68.6342 54.9305L59.276 52.2109C59.0712 52.1524 58.8782 52.0646 58.7027 51.9418C58.2114 51.6083 57.8839 51.0762 57.8196 50.4854L57.3867 46.783C60.007 44.7477 61.9488 41.7062 62.8788 38.1559C64.5517 38.0097 65.8325 36.1498 65.8325 33.8044C65.8325 31.6344 64.7446 29.8855 63.2532 29.5112"
      stroke="#001B31"
      strokeWidth="1.75853"
      strokeMiterlimit="8"
      strokeLinecap="round"
    />
    <path
      d="M36.827 29.5347C35.3765 29.9499 34.3295 31.6754 34.3295 33.8044C34.3295 36.1498 35.6045 38.0039 37.2774 38.156C38.2132 41.7062 40.1492 44.7477 42.7695 46.7831L42.3425 50.4854C42.2724 51.0762 41.9507 51.6085 41.4593 51.9418C41.2838 52.0588 41.0908 52.1524 40.8861 52.2109L31.5571 54.9189C30.3464 55.188 29.1123 55.8314 27.8956 56.8315C25.9831 58.4107 24.6671 60.61 24.1875 63.0255L22 73.9863L36.8211 77.057"
      stroke="#001B31"
      strokeWidth="1.75853"
      strokeMiterlimit="8"
      strokeLinecap="round"
    />
    <path
      d="M63.2416 29.5113C63.2416 29.5113 64.0136 14 50.0699 14C36.1261 14 36.8221 29.5347 36.8221 29.5347"
      stroke="#001B31"
      strokeWidth="1.75853"
      strokeMiterlimit="8"
      strokeLinecap="round"
    />
    <path
      d="M37.8164 22.8729C38.3604 21.7616 39.419 20.9018 40.5829 20.4807C42.4722 19.7964 44.5894 20.3404 46.4202 21.1767C49.0639 22.3816 51.3683 23.8029 54.3279 24.1246C57.147 24.4345 59.7322 24.1246 62.2707 22.832"
      stroke="#001B31"
      strokeWidth="1.75853"
      strokeMiterlimit="8"
      strokeLinecap="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M46.8417 33.7167H40.9752C40.56 33.7167 40.2266 33.3833 40.2266 32.968V29.7453C40.2266 29.33 40.56 28.9966 40.9752 28.9966H46.8417C47.2569 28.9966 47.5903 29.33 47.5903 29.7453V32.968C47.5903 33.3833 47.2511 33.7167 46.8417 33.7167Z"
      stroke="#001B31"
      strokeWidth="1.75853"
      strokeMiterlimit="8"
      strokeLinecap="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M59.1698 33.7167H53.3033C52.8881 33.7167 52.5547 33.3833 52.5547 32.968V29.7453C52.5547 29.33 52.8881 28.9966 53.3033 28.9966H59.1698C59.585 28.9966 59.9185 29.33 59.9185 29.7453V32.968C59.9185 33.3833 59.585 33.7167 59.1698 33.7167Z"
      stroke="#001B31"
      strokeWidth="1.75853"
      strokeMiterlimit="8"
      strokeLinecap="round"
    />
    <path
      d="M47.5898 30.7339H52.5555"
      stroke="#001B31"
      strokeWidth="1.75853"
      strokeMiterlimit="8"
      strokeLinecap="round"
    />
    <path
      d="M36.8203 28.9966L40.2185 30.5173"
      stroke="#001B31"
      strokeWidth="1.75853"
      strokeMiterlimit="8"
      strokeLinecap="round"
    />
    <path
      d="M59.918 30.5173L63.2461 28.9966"
      stroke="#001B31"
      strokeWidth="1.75853"
      strokeMiterlimit="8"
      strokeLinecap="round"
    />
    <path
      d="M57.709 49.626L50.0761 56.4633L42.4375 49.626"
      stroke="#001B31"
      strokeWidth="1.75853"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M62.2695 68.003L70.9084 66.377"
      stroke="#001B31"
      strokeWidth="1.75853"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const iconMap = (
  <svg
    className="icon-map"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.33203 13.1472L6.07066 14.7267L10.7166 12.0719L14.6654 13.6514V2.88199L10.6141 1.26147L5.92674 3.93996L1.33203 2.40839V13.1472ZM9.33203 11.3274L6.66536 12.8512V5.05356L9.33203 3.52975V11.3274ZM10.6654 10.6153L13.332 11.682V3.7847L10.7166 2.73854L10.6654 2.76784V10.6153ZM2.66536 12.1862L5.33203 13.0751V5.14718L2.66536 4.25829V12.1862Z"
      fill="#2E3A59"
    />
  </svg>
);

const iconMenu = (
  <svg
    className="icon-menu"
    width="29"
    height="47"
    viewBox="0 0 29 47"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M27.2791 23.2792H6.99999"
      stroke="#22B7C4"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M2 14.6396H27.2791"
      stroke="#006282"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M2 32.2793H27.2791"
      stroke="#006282"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const iconMinus = (
  <svg
    className="icon-minus"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M19 13H5V11H19V13Z" fill="#001B31" />
  </svg>
);

const iconMobilePhone = (
  <svg
    className="icon-mobile-phone"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.1667 0.666626H4.50008C3.76475 0.666626 3.16675 1.26463 3.16675 1.99996V14C3.16675 14.7353 3.76475 15.3333 4.50008 15.3333H11.1667C11.9021 15.3333 12.5001 14.7353 12.5001 14V1.99996C12.5001 1.26463 11.9021 0.666626 11.1667 0.666626ZM4.50008 14V1.99996H11.1667L11.1681 14H4.50008Z"
      fill="#006282"
    />
    <path
      d="M7.83341 13.3333C8.2016 13.3333 8.50008 13.0348 8.50008 12.6666C8.50008 12.2984 8.2016 12 7.83341 12C7.46522 12 7.16675 12.2984 7.16675 12.6666C7.16675 13.0348 7.46522 13.3333 7.83341 13.3333Z"
      fill="#006282"
    />
  </svg>
);

const iconPhone = (
  <svg
    className="icon-phone"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.8047 8.1953C11.5441 7.93463 11.1227 7.93463 10.8621 8.1953L9.79939 9.25796C9.30673 9.1113 8.38739 8.77796 7.80473 8.1953C7.22206 7.61263 6.88873 6.6933 6.74206 6.20063L7.80473 5.13796C8.06539 4.8773 8.06539 4.45596 7.80473 4.1953L5.13806 1.52863C4.87739 1.26796 4.45606 1.26796 4.19539 1.52863L2.38739 3.33663C2.13406 3.58996 1.99139 3.93796 1.99673 4.2933C2.01206 5.24263 2.26339 8.53996 4.86206 11.1386C7.46073 13.7373 10.7581 13.988 11.7081 14.004C11.7114 14.004 11.7234 14.004 11.7267 14.004C12.0787 14.004 12.4114 13.8653 12.6634 13.6133L14.4714 11.8053C14.7321 11.5446 14.7321 11.1233 14.4714 10.8626L11.8047 8.1953ZM11.7201 12.67C10.8881 12.656 8.04139 12.4326 5.80473 10.1953C3.56073 7.9513 3.34339 5.09463 3.33006 4.2793L4.66673 2.94263L6.39073 4.66663L5.52873 5.52863C5.36939 5.6873 5.30139 5.91663 5.34739 6.13663C5.36339 6.2133 5.75473 8.0313 6.86139 9.13796C7.96806 10.2446 9.78606 10.636 9.86273 10.652C10.0847 10.6993 10.3121 10.6306 10.4707 10.4713L11.3334 9.6093L13.0574 11.3333L11.7201 12.67Z"
      fill="#006282"
    />
  </svg>
);

const iconPlus = (
  <svg
    className="icon-plus"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M19 13H13V19H11V13H5V11H11V5H13V11H19V13Z" fill="#001B31" />
  </svg>
);

const iconQuote = (
  <svg
    className="icon-quote"
    width="46"
    height="46"
    viewBox="0 0 46 46"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.7428 32.5833C14.7203 32.5833 15.6212 32.0275 16.0428 31.165L18.7645 25.7216C19.0328 25.185 19.167 24.61 19.167 24.0158V15.3333C19.167 14.2791 18.3045 13.4166 17.2503 13.4166H9.58366C8.52949 13.4166 7.66699 14.2791 7.66699 15.3333V23C7.66699 24.0541 8.52949 24.9166 9.58366 24.9166H13.417L11.4428 28.865C10.5803 30.5708 11.8262 32.5833 13.7428 32.5833ZM32.9095 32.5833C33.887 32.5833 34.7878 32.0275 35.2095 31.165L37.9312 25.7216C38.1995 25.185 38.3337 24.61 38.3337 24.0158V15.3333C38.3337 14.2791 37.4712 13.4166 36.417 13.4166H28.7503C27.6962 13.4166 26.8337 14.2791 26.8337 15.3333V23C26.8337 24.0541 27.6962 24.9166 28.7503 24.9166H32.5837L30.6095 28.865C29.747 30.5708 30.9928 32.5833 32.9095 32.5833Z"
      fill="#F5F5F5"
    />
  </svg>
);

const iconSaveAlt = (
  <svg
    className="icon-save-alt"
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.25 9.75V13.5C14.25 13.9125 13.9125 14.25 13.5 14.25H4.5C4.0875 14.25 3.75 13.9125 3.75 13.5V9.75C3.75 9.3375 3.4125 9 3 9C2.5875 9 2.25 9.3375 2.25 9.75V14.25C2.25 15.075 2.925 15.75 3.75 15.75H14.25C15.075 15.75 15.75 15.075 15.75 14.25V9.75C15.75 9.3375 15.4125 9 15 9C14.5875 9 14.25 9.3375 14.25 9.75ZM9.75 9.5025L11.16 8.0925C11.4525 7.8 11.925 7.8 12.2175 8.0925C12.51 8.385 12.51 8.8575 12.2175 9.15L9.525 11.8425C9.2325 12.135 8.76 12.135 8.4675 11.8425L5.775 9.15C5.4825 8.8575 5.4825 8.385 5.775 8.0925C6.0675 7.8 6.54 7.8 6.8325 8.0925L8.25 9.5025V3C8.25 2.5875 8.5875 2.25 9 2.25C9.4125 2.25 9.75 2.5875 9.75 3V9.5025Z"
      fill="#E9E9EA"
    />
  </svg>
);

const iconSearch = (
  <svg
    className="icon-search"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.3755 12.4769C16.0454 10.1337 15.8292 6.85958 13.727 4.75736C11.3839 2.41421 7.58488 2.41421 5.24173 4.75736C2.89859 7.1005 2.89859 10.8995 5.24173 13.2426C7.34396 15.3449 10.618 15.561 12.9612 13.8911L18.6768 19.6066L20.091 18.1924L14.3755 12.4769ZM12.3128 6.17157C13.8749 7.73367 13.8749 10.2663 12.3128 11.8284C10.7507 13.3905 8.21805 13.3905 6.65595 11.8284C5.09385 10.2663 5.09385 7.73367 6.65595 6.17157C8.21805 4.60948 10.7507 4.60948 12.3128 6.17157Z"
      fill="#F8F8F8"
    />
  </svg>
);

const iconTeam = (
  <svg
    className="icon-team"
    width="100"
    height="100"
    viewBox="0 0 100 100"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M35.2324 52.3445C35.2324 51.59 36.3378 39.7284 36.3378 39.7284L44.3275 34.4995"
      stroke="#001B31"
      strokeWidth="1.75853"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M48.6959 46.0337L46.7891 52.3447"
      stroke="#001B31"
      strokeWidth="1.75853"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M64.3763 52.3445C64.3763 51.59 63.2709 39.7284 63.2709 39.7284L55.2812 34.4995"
      stroke="#001B31"
      strokeWidth="1.75853"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M55.2831 34.5054L46.7261 42.4072L48.6972 46.0336H49.8026H50.9081L52.8792 42.4072L44.3281 34.5054"
      stroke="#001B31"
      strokeWidth="1.75853"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M50.9062 46.0337L52.8188 52.3447"
      stroke="#001B31"
      strokeWidth="1.75853"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M55.2812 47.6421H59.551"
      stroke="#001B31"
      strokeWidth="1.75853"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M54.9366 26.2993C54.9366 29.7783 52.6375 32.5985 49.8013 32.5985C46.9651 32.5985 44.666 29.7783 44.666 26.2993C44.666 22.8203 46.9651 20 49.8013 20C52.6375 20 54.9366 22.8203 54.9366 26.2993Z"
      stroke="#001B31"
      strokeWidth="1.75853"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M63.1426 39.8455L68.5118 37.5879"
      stroke="#001B31"
      strokeWidth="1.75853"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M72.1907 40.1848L68.5117 37.5879"
      stroke="#001B31"
      strokeWidth="1.75853"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M82.5193 52.3798C82.5193 51.8709 81.2442 39.8514 81.2442 39.8514L75.875 37.5938"
      stroke="#001B31"
      strokeWidth="1.75853"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M75.8762 37.5879L72.1914 40.1848V47.6422"
      stroke="#001B31"
      strokeWidth="1.75853"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M76.033 30.195C76.033 32.8439 74.2836 34.9911 72.1259 34.9911C69.9681 34.9911 68.2188 32.8439 68.2188 30.195C68.2188 27.5462 69.9681 25.3989 72.1259 25.3989C74.2836 25.3989 76.033 27.5462 76.033 30.195Z"
      stroke="#001B31"
      strokeWidth="1.75853"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M36.467 39.8455L31.0977 37.5879"
      stroke="#001B31"
      strokeWidth="1.75853"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M27.4121 40.1848L31.0969 37.5879"
      stroke="#001B31"
      strokeWidth="1.75853"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M17.082 52.3798C17.082 51.8709 18.363 39.8514 18.363 39.8514L23.7322 37.5938"
      stroke="#001B31"
      strokeWidth="1.75853"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M23.7344 37.5879L27.4133 40.1848V47.6422"
      stroke="#001B31"
      strokeWidth="1.75853"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M31.3181 30.195C31.3181 32.8439 29.5688 34.9911 27.4109 34.9911C25.2532 34.9911 23.5039 32.8439 23.5039 30.195C23.5039 27.5462 25.2532 25.3989 27.4109 25.3989C29.5688 25.3989 31.3181 27.5462 31.3181 30.195Z"
      stroke="#001B31"
      strokeWidth="1.75853"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M81.2276 52.3506H18.3811C14.8602 52.3506 12 55.2049 12 58.7317V79.2146H25.0723V76.2492C25.0723 72.2719 28.295 69.0492 32.2723 69.0492H67.3365C71.3137 69.0492 74.5364 72.2719 74.5364 76.2492V79.2146H87.6088V58.7317C87.6088 55.2049 84.7487 52.3506 81.2276 52.3506V52.3506Z"
      stroke="#001B31"
      strokeWidth="1.75853"
      strokeMiterlimit="8"
    />
    <path
      d="M31.8809 63.8555H67.7289"
      stroke="#001B31"
      strokeWidth="1.75853"
      strokeMiterlimit="8"
      strokeLinecap="round"
    />
    <path
      d="M72.3926 20.5229C74.6322 20.5229 76.9794 21.7697 77.9174 23.8653C78.3291 24.7872 78.4984 25.8007 78.6151 26.8049C78.7798 28.221 78.853 29.5685 79.5302 30.845C80.0244 31.7761 80.7838 32.4326 81.9825 32.5768C81.9825 32.5768 80.5162 35.0909 77.3271 34.5442"
      stroke="#001B31"
      strokeWidth="1.75853"
      strokeMiterlimit="8"
      strokeLinecap="round"
    />
    <path
      d="M72.3927 20.5229C70.1531 20.5229 67.8059 21.7697 66.868 23.8653C66.4562 24.7872 66.2869 25.8007 66.1702 26.8049C66.0055 28.221 65.9323 29.5685 65.2551 30.845C64.761 31.7761 64.0015 32.4326 62.8027 32.5768C62.8027 32.5768 64.2691 35.0909 67.4582 34.5442"
      stroke="#001B31"
      strokeWidth="1.75853"
      strokeMiterlimit="8"
      strokeLinecap="round"
    />
  </svg>
);

const iconTick = (
  <svg
    fill="none"
    height="12"
    viewBox="0 0 16 12"
    width="16"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.98843 5.29253L6.93818 10.2423L5.52397 11.6565L0.574219 6.70675L1.98843 5.29253Z"
      fill="#001B31"
    />
    <path
      d="M5.52396 8.82805L14.0092 0.342773L15.4235 1.75699L6.93818 10.2423L5.52396 8.82805Z"
      fill="#001B31"
    />
  </svg>
);

const iconTwitter = (
  <svg
    className="icon-twitter"
    width="16"
    height="14"
    viewBox="0 0 16 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16 2C15.4 2.3 14.8 2.4 14.1 2.5C14.8 2.1 15.3 1.5 15.5 0.7C14.9 1.1 14.2 1.3 13.4 1.5C12.8 0.9 11.9 0.5 11 0.5C9.3 0.5 7.8 2 7.8 3.8C7.8 4.1 7.8 4.3 7.9 4.5C5.2 4.4 2.7 3.1 1.1 1.1C0.8 1.6 0.7 2.1 0.7 2.8C0.7 3.9 1.3 4.9 2.2 5.5C1.7 5.5 1.2 5.3 0.7 5.1C0.7 6.7 1.8 8 3.3 8.3C3 8.4 2.7 8.4 2.4 8.4C2.2 8.4 2 8.4 1.8 8.3C2.2 9.6 3.4 10.6 4.9 10.6C3.8 11.5 2.4 12 0.8 12C0.5 12 0.3 12 0 12C1.5 12.9 3.2 13.5 5 13.5C11 13.5 14.3 8.5 14.3 4.2C14.3 4.1 14.3 3.9 14.3 3.8C15 3.3 15.6 2.7 16 2Z"
      fill="#22B7C4"
    />
  </svg>
);

const iconYoutube = (
  <svg
    className="icon-youtube"
    width="16"
    height="12"
    viewBox="0 0 16 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.8 2.8C15.6 1.5 15 0.6 13.6 0.4C11.4 8.9407e-08 8 0 8 0C8 0 4.6 8.9407e-08 2.4 0.4C1 0.6 0.3 1.5 0.2 2.8C0 4.1 0 6 0 6C0 6 0 7.9 0.2 9.2C0.4 10.5 1 11.4 2.4 11.6C4.6 12 8 12 8 12C8 12 11.4 12 13.6 11.6C15 11.3 15.6 10.5 15.8 9.2C16 7.9 16 6 16 6C16 6 16 4.1 15.8 2.8ZM6 9V3L11 6L6 9Z"
      fill="#22B7C4"
    />
  </svg>
);

const iconDocumentBlank = (
  <svg
    className="icon-document-blank"
    width="12"
    height="14"
    viewBox="0 0 12 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.2926 4.78659C11.2853 4.76525 11.2793 4.74459 11.2706 4.72392C11.238 4.65325 11.1973 4.58592 11.14 4.52859L7.13997 0.528585C7.08263 0.471252 7.0153 0.430585 6.94463 0.397919C6.92463 0.388585 6.9033 0.383252 6.88197 0.375919C6.82597 0.357252 6.76864 0.345252 6.7093 0.341919C6.69464 0.340585 6.68197 0.333252 6.66797 0.333252H2.0013C1.26597 0.333252 0.667969 0.931252 0.667969 1.66659V12.3333C0.667969 13.0686 1.26597 13.6666 2.0013 13.6666H10.0013C10.7366 13.6666 11.3346 13.0686 11.3346 12.3333V4.99992C11.3346 4.98592 11.3273 4.97325 11.326 4.95859C11.3226 4.89992 11.3113 4.84259 11.2926 4.78659ZM9.05863 4.33325H7.33463V2.60925L9.05863 4.33325ZM2.0013 12.3333V1.66659H6.0013V4.99992C6.0013 5.36859 6.2993 5.66659 6.66797 5.66659H10.0013L10.0026 12.3333H2.0013Z"
      fill="#006282"
    />
  </svg>
);

const iconMail = (
  <svg
    className="icon-mail"
    width="14"
    height="12"
    viewBox="0 0 14 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.332031 2.00008C0.332031 1.2637 0.928985 0.666748 1.66536 0.666748H12.332C13.0684 0.666748 13.6654 1.2637 13.6654 2.00008V10.0001C13.6654 10.7365 13.0684 11.3334 12.332 11.3334H1.66536C0.928985 11.3334 0.332031 10.7365 0.332031 10.0001V2.00008ZM12.332 3.24575V10.0001H1.66536V3.24575L6.9987 6.80131L12.332 3.24575ZM11.7968 2.00008H2.20056L6.9987 5.19884L11.7968 2.00008Z"
      fill="#006282"
    />
  </svg>
);

const iconSettings = (
  <svg
    className="icon-settings"
    width="14"
    height="16"
    viewBox="0 0 14 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.0013 0.666748L13.3346 4.33341V11.6667L7.0013 15.3334L0.667969 11.6667V4.33341L7.0013 0.666748ZM7.0013 2.20741L2.0013 5.10208V10.8981L7.0013 13.7927L12.0013 10.8981V5.10208L7.0013 2.20741ZM7.0013 10.6667C6.29406 10.6667 5.61578 10.3858 5.11568 9.8857C4.61559 9.3856 4.33464 8.70732 4.33464 8.00008C4.33464 7.29284 4.61559 6.61456 5.11568 6.11446C5.61578 5.61437 6.29406 5.33341 7.0013 5.33341C7.70855 5.33341 8.38682 5.61437 8.88692 6.11446C9.38702 6.61456 9.66797 7.29284 9.66797 8.00008C9.66797 8.70732 9.38702 9.3856 8.88692 9.8857C8.38682 10.3858 7.70855 10.6667 7.0013 10.6667ZM7.0013 9.33341C7.35492 9.33341 7.69406 9.19294 7.94411 8.94289C8.19416 8.69284 8.33463 8.3537 8.33463 8.00008C8.33463 7.64646 8.19416 7.30732 7.94411 7.05727C7.69406 6.80722 7.35492 6.66675 7.0013 6.66675C6.64768 6.66675 6.30854 6.80722 6.05849 7.05727C5.80844 7.30732 5.66797 7.64646 5.66797 8.00008C5.66797 8.3537 5.80844 8.69284 6.05849 8.94289C6.30854 9.19294 6.64768 9.33341 7.0013 9.33341Z"
      fill="#006282"
    />
  </svg>
);

const iconPasswordHide = (
  <svg
    width="20"
    height="19"
    viewBox="0 0 20 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.41246 0.00195312L19.3813 16.9708L19.3831 16.969L19.3848 16.9708L17.9706 18.385L14.6137 15.0281C13.3568 15.6144 11.8332 16.0002 10 16.0002C2.408 16.0002 0.126318 9.3832 0.104436 9.3162L0 9.0002L0.105431 8.6842C0.118626 8.64187 1.03404 5.98562 3.62047 4.03488L1 1.41441L2.41246 0.00195312ZM13.0867 13.5011L11.2895 11.7039C10.8976 11.8936 10.4599 12.0002 10 12.0002C8.358 12.0002 7 10.6422 7 9.0002C7 8.54033 7.10665 8.1026 7.29631 7.71072L5.03632 5.45073C3.26898 6.66613 2.41537 8.30996 2.11657 9.0002C2.61587 10.1542 4.67774 14.0002 10 14.0002C11.184 14.0002 12.2067 13.8098 13.0867 13.5011Z"
      fill="#B9B9BA"
    />
    <path
      d="M7.50022 2.26128C8.26109 2.09472 9.09262 2.0002 10 2.0002C17.592 2.0002 19.8737 8.6172 19.8956 8.6842L20 9.0002L19.8946 9.3162C19.8843 9.34922 19.3248 10.9733 17.8511 12.6121L16.4594 11.2205C17.2458 10.3298 17.6859 9.45657 17.8834 9.0002C17.3841 7.8462 15.3223 4.0002 10 4.0002C9.74753 4.0002 9.50239 4.00886 9.2644 4.02547L7.50022 2.26128Z"
      fill="#B9B9BA"
    />
  </svg>
);

const iconPasswordShow = (
  <svg
    width="20"
    height="14"
    viewBox="0 0 20 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10 4C8.358 4 7 5.359 7 7C7 8.642 8.358 10 10 10C11.641 10 13 8.642 13 7C13 5.359 11.641 4 10 4Z"
      fill="#22B7C4"
    />
    <path
      d="M10 0C2.408 0 0.126318 6.617 0.105431 6.684L0 7L0.104436 7.316C0.126318 7.383 2.408 14 10 14C17.592 14 19.8737 7.383 19.8946 7.316L20 7L19.8956 6.684C19.8737 6.617 17.592 0 10 0ZM10 12C4.67774 12 2.61587 8.154 2.11657 7C2.61786 5.842 4.68072 2 10 2C15.3223 2 17.3841 5.846 17.8834 7C17.3821 8.158 15.3193 12 10 12Z"
      fill="#22B7C4"
    />
  </svg>
);

const iconImage = (
  <svg
    width="22"
    height="16"
    viewBox="0 0 22 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M22 14V2C22 0.9 21.1 0 20 0H2C0.9 0 0 0.9 0 2V14C0 15.1 0.9 16 2 16H20C21.1 16 22 15.1 22 14ZM7.9 8.98L10 11.51L13.1 7.52C13.3 7.26 13.7 7.26 13.9 7.53L17.41 12.21C17.66 12.54 17.42 13.01 17.01 13.01H5.02C4.6 13.01 4.37 12.53 4.63 12.2L7.12 9C7.31 8.74 7.69 8.73 7.9 8.98Z"
      fill="#011a2f"
    />
  </svg>
);

const iconCloseSmall = (
  <svg
    width="10"
    height="10"
    viewBox="0 0 10 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10 1.41L8.59 0L5 3.59L1.41 0L0 1.41L3.59 5L0 8.59L1.41 10L5 6.41L8.59 10L10 8.59L6.41 5L10 1.41Z"
      fill="white"
    />
  </svg>
);

const iconLaptop = (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.6667 3.33331H3.33333C2.59695 3.33331 2 3.93027 2 4.66665V10.6666H0.666664V11.3333C0.666664 12.0697 1.26362 12.6666 2 12.6666H14C14.7364 12.6666 15.3333 12.0697 15.3333 11.3333V10.6666H14V4.66665C14 3.93027 13.403 3.33331 12.6667 3.33331ZM12.6667 10.6666H3.33333V4.66665H12.6667V10.6666Z"
      fill="#006282"
    />
  </svg>
);

//
// Functional Icons with embed gradients
//
const iconPortalCalendar = (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M7 12H9V14H7V12Z" fill="url(#paint0_linear)" />
    <path d="M7 16H9V18H7V16Z" fill="url(#paint1_linear)" />
    <path d="M13 12H11V14H13V12Z" fill="url(#paint2_linear)" />
    <path d="M11 16H13V18H11V16Z" fill="url(#paint3_linear)" />
    <path d="M17 12H15V14H17V12Z" fill="url(#paint4_linear)" />
    <path d="M15 16H17V18H15V16Z" fill="url(#paint5_linear)" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19 22H5C3.897 22 3 21.103 3 20V6C3 4.897 3.897 4 5 4H7V2H9V4H15V2H17V4H19C20.103 4 21 4.897 21 6V20C21 21.103 20.103 22 19 22ZM19.0001 8L19 6H5V8H19.0001ZM19.0003 10L19.001 20H5V10H19.0003Z"
      fill="url(#paint6_linear)"
    />
    <defs>
      <linearGradient
        id="paint0_linear"
        x1="3"
        y1="2"
        x2="17.8403"
        y2="-0.374332"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#23728E" />
        <stop offset="1" stopColor="#001B31" />
      </linearGradient>
      <linearGradient
        id="paint1_linear"
        x1="3"
        y1="2"
        x2="17.8403"
        y2="-0.374332"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#23728E" />
        <stop offset="1" stopColor="#001B31" />
      </linearGradient>
      <linearGradient
        id="paint2_linear"
        x1="3"
        y1="2"
        x2="17.8403"
        y2="-0.374332"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#23728E" />
        <stop offset="1" stopColor="#001B31" />
      </linearGradient>
      <linearGradient
        id="paint3_linear"
        x1="3"
        y1="2"
        x2="17.8403"
        y2="-0.374332"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#23728E" />
        <stop offset="1" stopColor="#001B31" />
      </linearGradient>
      <linearGradient
        id="paint4_linear"
        x1="3"
        y1="2"
        x2="17.8403"
        y2="-0.374332"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#23728E" />
        <stop offset="1" stopColor="#001B31" />
      </linearGradient>
      <linearGradient
        id="paint5_linear"
        x1="3"
        y1="2"
        x2="17.8403"
        y2="-0.374332"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#23728E" />
        <stop offset="1" stopColor="#001B31" />
      </linearGradient>
      <linearGradient
        id="paint6_linear"
        x1="3"
        y1="2"
        x2="17.8403"
        y2="-0.374332"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#23728E" />
        <stop offset="1" stopColor="#001B31" />
      </linearGradient>
    </defs>
  </svg>
);

const iconPortalEvent = (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.6293 14.1827L12.8292 12.3837L14.1999 11.0122L16 12.8113L14.6293 14.1827Z"
      fill="url(#paint0_linear)"
    />
    <path
      d="M8.005 19V17.201L12.201 13.011L14 14.81L9.804 19H8.005Z"
      fill="url(#paint1_linear)"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19 4H17V2H15V4H9V2H7V4H5C3.897 4 3 4.897 3 6V20C3 21.103 3.897 22 5 22H19C20.103 22 21 21.103 21 20V6C21 4.897 20.103 4 19 4ZM5 20V10H19L19.002 20H5ZM19.0003 8L19 6H5V8H19.0003Z"
      fill="url(#paint2_linear)"
    />
    <defs>
      <linearGradient
        id="paint0_linear"
        x1="3"
        y1="2"
        x2="17.8403"
        y2="-0.374332"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#23728E" />
        <stop offset="1" stopColor="#001B31" />
      </linearGradient>
      <linearGradient
        id="paint1_linear"
        x1="3"
        y1="2"
        x2="17.8403"
        y2="-0.374332"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#23728E" />
        <stop offset="1" stopColor="#001B31" />
      </linearGradient>
      <linearGradient
        id="paint2_linear"
        x1="3"
        y1="2"
        x2="17.8403"
        y2="-0.374332"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#23728E" />
        <stop offset="1" stopColor="#001B31" />
      </linearGradient>
    </defs>
  </svg>
);

const iconPortalLayers = (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.99 18.6052L4.62 12.8752L3 14.1352L12 21.1352L21 14.1352L19.37 12.8652L11.99 18.6052Z"
      fill="url(#paint0_linear)"
    />
    <path
      d="M12 16.8652L19.36 11.1352L21 9.86523L12 2.86523L3 9.86523L4.63 11.1352L12 16.8652Z"
      fill="url(#paint1_linear)"
    />
    <defs>
      <linearGradient
        id="paint0_linear"
        x1="3"
        y1="2.86523"
        x2="17.7671"
        y2="0.278879"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#23728E" />
        <stop offset="1" stopColor="#001B31" />
      </linearGradient>
      <linearGradient
        id="paint1_linear"
        x1="3"
        y1="2.86523"
        x2="17.7671"
        y2="0.278879"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#23728E" />
        <stop offset="1" stopColor="#001B31" />
      </linearGradient>
    </defs>
  </svg>
);

const iconPortalPhone = (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17.7083 12.2933C17.3173 11.9023 16.6853 11.9023 16.2943 12.2933L14.7003 13.8872C13.9613 13.6672 12.5823 13.1672 11.7083 12.2933C10.8343 11.4193 10.3343 10.0402 10.1143 9.30125L11.7083 7.70725C12.0993 7.31625 12.0993 6.68425 11.7083 6.29325L7.70831 2.29325C7.31731 1.90225 6.68531 1.90225 6.29431 2.29325L3.58231 5.00525C3.20231 5.38525 2.98831 5.90725 2.99631 6.44025C3.01931 7.86425 3.39631 12.8103 7.29431 16.7083C11.1923 20.6063 16.1383 20.9823 17.5633 21.0063C17.5683 21.0063 17.5863 21.0063 17.5913 21.0063C18.1193 21.0063 18.6183 20.7983 18.9963 20.4202L21.7083 17.7083C22.0993 17.3173 22.0993 16.6853 21.7083 16.2943L17.7083 12.2933ZM17.5813 19.0052C16.3333 18.9842 12.0633 18.6493 8.70831 15.2933C5.34231 11.9272 5.01631 7.64225 4.99631 6.41925L7.00131 4.41425L9.58731 7.00025L8.29431 8.29325C8.05531 8.53125 7.95331 8.87525 8.02231 9.20525C8.04631 9.32025 8.63331 12.0472 10.2933 13.7072C11.9533 15.3672 14.6803 15.9542 14.7953 15.9782C15.1283 16.0492 15.4693 15.9462 15.7073 15.7072L17.0013 14.4142L19.5873 17.0002L17.5813 19.0052Z"
      fill="url(#paint0_linear)"
    />
    <defs>
      <linearGradient
        id="paint0_linear"
        x1="2.99609"
        y1="2"
        x2="18.5742"
        y2="-0.769191"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#23728E" />
        <stop offset="1" stopColor="#001B31" />
      </linearGradient>
    </defs>
  </svg>
);

const iconPortalMobile = (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16.75 1H6.75C5.647 1 4.75 1.897 4.75 3V21C4.75 22.103 5.647 23 6.75 23H16.75C17.853 23 18.75 22.103 18.75 21V3C18.75 1.897 17.853 1 16.75 1ZM6.75 21V3H16.75L16.752 21H6.75Z"
      fill="url(#paint0_linear)"
    />
    <path
      d="M11.75 20C12.3023 20 12.75 19.5523 12.75 19C12.75 18.4477 12.3023 18 11.75 18C11.1977 18 10.75 18.4477 10.75 19C10.75 19.5523 11.1977 20 11.75 20Z"
      fill="url(#paint1_linear)"
    />
    <defs>
      <linearGradient
        id="paint0_linear"
        x1="4.75"
        y1="1"
        x2="16.4383"
        y2="-0.322251"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#23728E" />
        <stop offset="1" stopColor="#001B31" />
      </linearGradient>
      <linearGradient
        id="paint1_linear"
        x1="4.75"
        y1="1"
        x2="16.4383"
        y2="-0.322251"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#23728E" />
        <stop offset="1" stopColor="#001B31" />
      </linearGradient>
    </defs>
  </svg>
);

const iconPortalMail = (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M19.2 5H4.8C3.81 5 3.009 5.7875 3.009 6.75L3 17.25C3 18.2125 3.81 19 4.8 19H19.2C20.19 19 21 18.2125 21 17.25V6.75C21 5.7875 20.19 5 19.2 5ZM18.3 17.25H5.7C5.205 17.25 4.8 16.8562 4.8 16.375V8.5L11.046 12.2975C11.631 12.6562 12.369 12.6562 12.954 12.2975L19.2 8.5V16.375C19.2 16.8562 18.795 17.25 18.3 17.25ZM12 11.125L4.8 6.75H19.2L12 11.125Z"
      fill="url(#paint0_linear)"
    />
    <defs>
      <linearGradient
        id="paint0_linear"
        x1="3"
        y1="5"
        x2="17.4645"
        y2="1.69398"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#23728E" />
        <stop offset="1" stopColor="#001B31" />
      </linearGradient>
    </defs>
  </svg>
);

const iconPortalLocation = (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 3C8.13 3 5 6.13 5 10C5 15.25 12 21 12 21C12 21 19 15.25 19 10C19 6.13 15.87 3 12 3ZM12 13C13.6569 13 15 11.6569 15 10C15 8.34315 13.6569 7 12 7C10.3431 7 9 8.34315 9 10C9 11.6569 10.3431 13 12 13Z"
      fill="url(#paint0_linear)"
    />
    <defs>
      <linearGradient
        id="paint0_linear"
        x1="5"
        y1="3"
        x2="16.6158"
        y2="1.39394"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#23728E" />
        <stop offset="1" stopColor="#001B31" />
      </linearGradient>
    </defs>
  </svg>
);

const iconPortalMoney = (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM13.41 18.09V18.67C13.41 19.4 12.81 20 12.08 20H12.07C11.34 20 10.74 19.4 10.74 18.67V18.07C9.41 17.79 8.23 17.06 7.73 15.83C7.5 15.28 7.93 14.67 8.53 14.67H8.77C9.14 14.67 9.44 14.92 9.58 15.27C9.87 16.02 10.63 16.54 12.09 16.54C14.05 16.54 14.49 15.56 14.49 14.95C14.49 14.12 14.05 13.34 11.82 12.81C9.34 12.21 7.64 11.19 7.64 9.14C7.64 7.42 9.03 6.3 10.75 5.93V5.33C10.75 4.6 11.35 4 12.08 4H12.09C12.82 4 13.42 4.6 13.42 5.33V5.95C14.8 6.29 15.67 7.15 16.05 8.21C16.25 8.76 15.83 9.34 15.24 9.34H14.98C14.61 9.34 14.31 9.08 14.21 8.72C13.98 7.96 13.35 7.47 12.09 7.47C10.59 7.47 9.69 8.15 9.69 9.11C9.69 9.95 10.34 10.5 12.36 11.02C14.38 11.54 16.54 12.41 16.54 14.93C16.52 16.76 15.15 17.76 13.41 18.09Z"
      fill="url(#paint0_linear)"
    />
    <defs>
      <linearGradient
        id="paint0_linear"
        x1="2"
        y1="2"
        x2="18.3932"
        y2="-0.914212"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#23728E" />
        <stop offset="1" stopColor="#001B31" />
      </linearGradient>
    </defs>
  </svg>
);

// const iconPortalDownloadCloud = (
// 	<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
// 		<path
// 			fillRule="evenodd"
// 			clipRule="evenodd"
// 			d="M12 4C15.7485 4 18.8948 6.57805 19.7626 10.0578C22.1621 10.425 24 12.4979 24 15C24 17.7614 21.7614 20 19 20H6C2.68629 20 0 17.3137 0 14C0 11.0182 2.17514 8.54439 5.02529 8.07877C6.39696 5.64419 9.00647 4 12 4ZM5.34774 10.0526L6.29608 9.89768L6.76775 9.06051C7.79944 7.22938 9.75746 6 12 6C14.8087 6 17.1711 7.93171 17.822 10.5418L18.1442 11.8334L19.4601 12.0348C20.898 12.2548 22 13.5005 22 15C22 16.6569 20.6569 18 19 18H6C3.79086 18 2 16.2091 2 14C2 12.0141 3.44924 10.3628 5.34774 10.0526Z"
// 			fill="url(#paint0_linear)"/>
// 		<path d="M13.45 12H16L12 16L8 12H10.55L10.55 9L13.45 9L13.45 12Z" fill="url(#paint1_linear)"/>
// 		<defs>
// 			<linearGradient id="paint0_linear" x1="0" y1="4" x2="18.9463" y2="-1.05212" gradientUnits="userSpaceOnUse">
// 				<stop stopColor="#23728E"/>
// 				<stop offset="1" stopColor="#001B31"/>
// 			</linearGradient>
// 			<linearGradient id="paint1_linear" x1="0" y1="4" x2="18.9463" y2="-1.05212" gradientUnits="userSpaceOnUse">
// 				<stop stopColor="#23728E"/>
// 				<stop offset="1" stopColor="#001B31"/>
// 			</linearGradient>
// 		</defs>
// 	</svg>
// )

const iconPortalSurvey = (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M19 2H14.82C14.4 0.84 13.3 0 12 0C10.7 0 9.6 0.84 9.18 2H5C3.9 2 3 2.9 3 4V20C3 21.1 3.9 22 5 22H19C20.1 22 21 21.1 21 20V4C21 2.9 20.1 2 19 2ZM12 2C12.55 2 13 2.45 13 3C13 3.55 12.55 4 12 4C11.45 4 11 3.55 11 3C11 2.45 11.45 2 12 2ZM18 20H6C5.45 20 5 19.55 5 19V5C5 4.45 5.45 4 6 4H7V5C7 6.1 7.9 7 9 7H15C16.1 7 17 6.1 17 5V4H18C18.55 4 19 4.45 19 5V19C19 19.55 18.55 20 18 20Z"
      fill="url(#paint0_linear)"
    />
    <defs>
      <linearGradient
        id="paint0_linear"
        x1="3"
        y1="0"
        x2="17.9048"
        y2="-2.16787"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#23728E" />
        <stop offset="1" stopColor="#001B31" />
      </linearGradient>
    </defs>
  </svg>
);

const iconPortalVideocam = (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.8 15.9L15.47 12.4C15.74 12.2 15.74 11.8 15.47 11.6L10.8 8.1C10.47 7.85 10 8.09 10 8.5V15.5C10 15.91 10.47 16.15 10.8 15.9ZM12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM12 20C7.59 20 4 16.41 4 12C4 7.59 7.59 4 12 4C16.41 4 20 7.59 20 12C20 16.41 16.41 20 12 20Z"
      fill="url(#paint0_linear)"
    />
    <defs>
      <linearGradient
        id="paint0_linear"
        x1="2"
        y1="2"
        x2="18.3932"
        y2="-0.914212"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#23728E" />
        <stop offset="1" stopColor="#001B31" />
      </linearGradient>
    </defs>
  </svg>
);

// const iconPortalFirst = (
// 	<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
// 		<path d="M9.746 18V7.376H8.258C7.49 7.808 6.658 8.24 5.97 8.592V10.64C6.402 10.464 6.994 10.208 7.474 9.984V18H9.746Z" fill="url(#paint0_linear)"/>
// 		<path
// 			d="M14.897 7.779C14.456 7.365 13.889 7.158 13.259 7.158C12.395 7.158 11.567 7.644 11.567 8.607C11.567 9.435 12.188 9.759 12.755 10.002C13.223 10.209 13.655 10.353 13.655 10.686C13.655 10.974 13.313 11.1 13.052 11.1C12.593 11.1 12.296 10.893 11.936 10.542L11.207 11.298C11.729 11.82 12.377 12.108 13.115 12.108C14.033 12.108 14.933 11.631 14.933 10.587C14.933 9.687 14.159 9.381 13.547 9.129C13.151 8.967 12.8 8.814 12.8 8.517C12.8 8.292 13.016 8.157 13.259 8.157C13.619 8.157 13.952 8.337 14.195 8.553L14.897 7.779ZM18.492 8.328V7.266H17.286V6.222H16.206L16.008 7.266H15.441V8.328H16.008V10.299C16.008 10.578 16.026 10.857 16.098 11.091C16.269 11.703 16.764 12.108 17.502 12.108C17.871 12.108 18.321 12.009 18.627 11.829L18.231 10.884C18.069 10.965 17.916 11.019 17.718 11.019C17.448 11.019 17.34 10.848 17.304 10.569C17.286 10.434 17.286 10.308 17.286 10.164V8.328H18.492Z"
// 			fill="url(#paint1_linear)"/>
// 		<defs>
// 			<linearGradient id="paint0_linear" x1="5" y1="1" x2="10.0574" y2="0.716087" gradientUnits="userSpaceOnUse">
// 				<stop stopColor="#23728E"/>
// 				<stop offset="1" stopColor="#001B31"/>
// 			</linearGradient>
// 			<linearGradient id="paint1_linear" x1="11" y1="2" x2="17.6954" y2="1.31986" gradientUnits="userSpaceOnUse">
// 				<stop stopColor="#23728E"/>
// 				<stop offset="1" stopColor="#001B31"/>
// 			</linearGradient>
// 		</defs>
// 	</svg>
// )

// const iconPortalSecond = (
// 	<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
// 		<path
// 			d="M10.6094 16.096H6.51338C7.37738 15.168 8.30538 14.16 9.07338 13.088C9.69738 12.208 10.1614 11.184 10.1614 10.128C10.1614 8.24 8.48138 7.184 6.72137 7.184C4.94538 7.184 3.36138 8.368 3.21738 10.208L5.47338 10.576C5.47338 9.664 5.93738 9.072 6.68938 9.072C7.36138 9.072 7.85738 9.504 7.85738 10.16C7.85738 10.784 7.47338 11.472 7.08938 12.032C6.14538 13.392 4.70538 15.04 3.47338 16.32V18H10.6094V16.096Z"
// 			fill="url(#paint0_linear)"/>
// 		<path
// 			d="M11.621 7.266V12H12.899V8.859C13.133 8.472 13.547 8.256 13.925 8.256C14.204 8.256 14.438 8.355 14.555 8.589C14.69 8.859 14.699 9.255 14.699 9.606V12H15.977V9.543C15.977 8.868 15.923 8.256 15.554 7.779C15.257 7.401 14.807 7.158 14.294 7.158C13.772 7.158 13.205 7.383 12.827 7.914L12.728 7.266H11.621ZM21.7385 12V5.25H20.4605V7.725C20.1005 7.32 19.5335 7.158 19.0835 7.158C17.7965 7.158 16.8605 8.283 16.8605 9.615C16.8605 10.992 17.8145 12.108 19.0655 12.108C19.5515 12.108 20.1905 11.937 20.5235 11.415L20.6315 12H21.7385ZM20.4605 10.389C20.1815 10.857 19.7495 11.01 19.3445 11.01C18.6155 11.01 18.1655 10.344 18.1655 9.651C18.1655 8.985 18.5435 8.256 19.3625 8.256C19.7585 8.256 20.1365 8.391 20.4605 8.769V10.389Z"
// 			fill="url(#paint1_linear)"/>
// 		<defs>
// 			<linearGradient id="paint0_linear" x1="5" y1="1" x2="10.0574" y2="0.716087" gradientUnits="userSpaceOnUse">
// 				<stop stopColor="#23728E"/>
// 				<stop offset="1" stopColor="#001B31"/>
// 			</linearGradient>
// 			<linearGradient id="paint1_linear" x1="11" y1="2" x2="20.9165" y2="0.488982" gradientUnits="userSpaceOnUse">
// 				<stop stopColor="#23728E"/>
// 				<stop offset="1" stopColor="#001B31"/>
// 			</linearGradient>
// 		</defs>
// 	</svg>
// )

// const iconPortalThird = (
// 	<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
// 		<path
// 			d="M5.23788 13.536C5.54188 13.472 5.89388 13.456 6.08588 13.456C7.22188 13.456 8.03788 13.968 8.03788 14.944C8.03788 15.808 7.31788 16.272 6.51788 16.272C5.70188 16.272 5.09388 15.824 4.77387 15.056L2.86988 15.936C3.50988 17.424 4.99788 18.192 6.50188 18.192C8.59788 18.192 10.3419 16.992 10.3419 14.928C10.3419 13.76 9.63788 12.704 8.54988 12.304C9.33388 11.856 9.86188 10.944 9.86188 10.032C9.86188 8.272 8.13388 7.184 6.26188 7.184C4.82188 7.184 3.49388 7.936 3.04588 9.344L4.96588 10.176C5.09388 9.488 5.60588 9.104 6.24588 9.104C7.09388 9.104 7.58988 9.632 7.58988 10.288C7.58988 10.928 7.15788 11.392 6.48588 11.552C6.19788 11.632 5.73388 11.68 5.23788 11.68V13.536Z"
// 			fill="url(#paint0_linear)"/>
// 		<path
// 			d="M12.728 7.266H11.621V12H12.899V8.796C13.088 8.481 13.421 8.274 13.79 8.274C13.952 8.274 14.105 8.31 14.24 8.382L14.546 7.266C14.375 7.203 14.168 7.158 13.934 7.158C13.466 7.158 13.097 7.455 12.827 7.896L12.728 7.266ZM19.5412 12V5.25H18.2632V7.725C17.9032 7.32 17.3362 7.158 16.8862 7.158C15.5992 7.158 14.6632 8.283 14.6632 9.615C14.6632 10.992 15.6172 12.108 16.8682 12.108C17.3542 12.108 17.9932 11.937 18.3262 11.415L18.4342 12H19.5412ZM18.2632 10.389C17.9842 10.857 17.5522 11.01 17.1472 11.01C16.4182 11.01 15.9682 10.344 15.9682 9.651C15.9682 8.985 16.3462 8.256 17.1652 8.256C17.5612 8.256 17.9392 8.391 18.2632 8.769V10.389Z"
// 			fill="url(#paint1_linear)"/>
// 		<defs>
// 			<linearGradient id="paint0_linear" x1="5" y1="1" x2="10.0574" y2="0.716087" gradientUnits="userSpaceOnUse">
// 				<stop stopColor="#23728E"/>
// 				<stop offset="1" stopColor="#001B31"/>
// 			</linearGradient>
// 			<linearGradient id="paint1_linear" x1="11" y1="2" x2="19.3215" y2="0.943356" gradientUnits="userSpaceOnUse">
// 				<stop stopColor="#23728E"/>
// 				<stop offset="1" stopColor="#001B31"/>
// 			</linearGradient>
// 		</defs>
// 	</svg>
// )

const iconPortalDownload = (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.2857 5L11.2857 13.519L8.72143 11.013L7.71429 12L12 16.2L16.2857 12L15.2786 11.013L12.7143 13.519L12.7143 5L11.2857 5Z"
      fill="url(#paint0_linear)"
    />
    <path d="M7 17.6H17V19H7V17.6Z" fill="url(#paint1_linear)" />
    <defs>
      <linearGradient
        id="paint0_linear"
        x1="7"
        y1="5"
        x2="15.3215"
        y2="3.94336"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#23728E" />
        <stop offset="1" stopColor="#001B31" />
      </linearGradient>
      <linearGradient
        id="paint1_linear"
        x1="7"
        y1="5"
        x2="15.3215"
        y2="3.94336"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#23728E" />
        <stop offset="1" stopColor="#001B31" />
      </linearGradient>
    </defs>
  </svg>
);

const iconPortalEdit = (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.9829 5.48097L3.6692 16.7947L3.42429 19.4887C3.36775 20.1106 3.88879 20.6316 4.51072 20.5751L7.20474 20.3302L18.5184 9.01651L14.9829 5.48097Z"
      fill="url(#paint0_linear)"
    />
    <path
      d="M19.2255 8.3094L21.3469 6.18806C21.7374 5.79754 21.7374 5.16437 21.3469 4.77385L19.2256 2.65253C18.835 2.262 18.2019 2.262 17.8113 2.65253L15.69 4.77386L19.2255 8.3094Z"
      fill="url(#paint1_linear)"
    />
    <defs>
      <linearGradient
        id="paint0_linear"
        x1="1.94531"
        y1="18.5186"
        x2="3.32392"
        y2="3.35613"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#23728E" />
        <stop offset="1" stopColor="#001B31" />
      </linearGradient>
      <linearGradient
        id="paint1_linear"
        x1="1.94531"
        y1="18.5186"
        x2="3.32392"
        y2="3.35613"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#23728E" />
        <stop offset="1" stopColor="#001B31" />
      </linearGradient>
    </defs>
  </svg>
);

const iconPortalUser = (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12 2C9.243 2 7 4.243 7 7C7 9.757 9.243 12 12 12C14.757 12 17 9.757 17 7C17 4.243 14.757 2 12 2ZM12 10C10.346 10 9 8.654 9 7C9 5.346 10.346 4 12 4C13.654 4 15 5.346 15 7C15 8.654 13.654 10 12 10ZM21 21V20C21 16.141 17.859 13 14 13H10C6.14 13 3 16.141 3 20V21H5V20C5 17.243 7.243 15 10 15H14C16.757 15 19 17.243 19 20V21H21Z"
      fill="url(#paint0_linear)"
    />
    <defs>
      <linearGradient
        id="paint0_linear"
        x1="3"
        y1="2"
        x2="17.8003"
        y2="-0.492575"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#23728E" />
        <stop offset="1" stopColor="#001B31" />
      </linearGradient>
    </defs>
  </svg>
);

// const iconPortalDepartment = (
// 	<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
// 		<path
// 			d="M4 11.5003V15.5003C4 16.3303 4.67 17.0003 5.5 17.0003C6.33 17.0003 7 16.3303 7 15.5003V11.5003C7 10.6703 6.33 10.0003 5.5 10.0003C4.67 10.0003 4 10.6703 4 11.5003ZM10 11.5003V15.5003C10 16.3303 10.67 17.0003 11.5 17.0003C12.33 17.0003 13 16.3303 13 15.5003V11.5003C13 10.6703 12.33 10.0003 11.5 10.0003C10.67 10.0003 10 10.6703 10 11.5003ZM3.5 22.0003H19.5C20.33 22.0003 21 21.3303 21 20.5003C21 19.6703 20.33 19.0003 19.5 19.0003H3.5C2.67 19.0003 2 19.6703 2 20.5003C2 21.3303 2.67 22.0003 3.5 22.0003ZM16 11.5003V15.5003C16 16.3303 16.67 17.0003 17.5 17.0003C18.33 17.0003 19 16.3303 19 15.5003V11.5003C19 10.6703 18.33 10.0003 17.5 10.0003C16.67 10.0003 16 10.6703 16 11.5003ZM10.57 1.49031L2.67 5.65031C2.26 5.86031 2 6.29031 2 6.75031C2 7.44031 2.56 8.00031 3.25 8.00031H19.76C20.44 8.00031 21 7.44031 21 6.75031C21 6.29031 20.74 5.86031 20.33 5.65031L12.43 1.49031C11.85 1.18031 11.15 1.18031 10.57 1.49031Z"
// 			fill="url(#paint0_linear)"/>
// 		<defs>
// 			<linearGradient id="paint0_linear" x1="2" y1="1.25781" x2="17.6507" y2="-1.29068" gradientUnits="userSpaceOnUse">
// 				<stop stopColor="#23728E"/>
// 				<stop offset="1" stopColor="#001B31"/>
// 			</linearGradient>
// 		</defs>
// 	</svg>
// )

const iconPortalCopyFile = (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15 1H4C2.9 1 2 1.9 2 3V16C2 16.55 2.45 17 3 17C3.55 17 4 16.55 4 16V4C4 3.45 4.45 3 5 3H15C15.55 3 16 2.55 16 2C16 1.45 15.55 1 15 1ZM19 5H8C6.9 5 6 5.9 6 7V21C6 22.1 6.9 23 8 23H19C20.1 23 21 22.1 21 21V7C21 5.9 20.1 5 19 5ZM18 21H9C8.45 21 8 20.55 8 20V8C8 7.45 8.45 7 9 7H18C18.55 7 19 7.45 19 8V20C19 20.55 18.55 21 18 21Z"
      fill="url(#paint0_linear)"
    />
    <defs>
      <linearGradient
        id="paint0_linear"
        x1="2"
        y1="1"
        x2="17.6957"
        y2="-1.40974"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#23728E" />
        <stop offset="1" stopColor="#001B31" />
      </linearGradient>
    </defs>
  </svg>
);

const iconPortalJob = (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14 6V4H10V6H14ZM4 9V18C4 18.55 4.45 19 5 19H19C19.55 19 20 18.55 20 18V9C20 8.45 19.55 8 19 8H5C4.45 8 4 8.45 4 9ZM20 6C21.11 6 22 6.89 22 8V19C22 20.11 21.11 21 20 21H4C2.89 21 2 20.11 2 19L2.01 8C2.01 6.89 2.89 6 4 6H8V4C8 2.89 8.89 2 10 2H14C15.11 2 16 2.89 16 4V6H20Z"
      fill="url(#paint0_linear)"
    />
    <defs>
      <linearGradient
        id="paint0_linear"
        x1="2"
        y1="2"
        x2="18.3391"
        y2="-1.05747"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#23728E" />
        <stop offset="1" stopColor="#001B31" />
      </linearGradient>
    </defs>
  </svg>
);

const iconPortalComment = (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M20 2H4C2.9 2 2 2.9 2 4V22L6 18H20C21.1 18 22 17.1 22 16V4C22 2.9 21.1 2 20 2ZM19 16H6L4 18V5C4 4.45 4.45 4 5 4H19C19.55 4 20 4.45 20 5V15C20 15.55 19.55 16 19 16Z"
      fill="url(#paint0_linear)"
    />
    <defs>
      <linearGradient
        id="paint0_linear"
        x1="2"
        y1="2"
        x2="18.3932"
        y2="-0.914212"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#23728E" />
        <stop offset="1" stopColor="#001B31" />
      </linearGradient>
    </defs>
  </svg>
);

// const iconPortalAbn = (
// 	<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
// 		<path
// 			d="M12 1L21.5 6.5V17.5L12 23L2.5 17.5V6.5L12 1ZM12 3.311L4.5 7.653V16.347L12 20.689L19.5 16.347V7.653L12 3.311ZM12 16C10.9391 16 9.92172 15.5786 9.17157 14.8284C8.42143 14.0783 8 13.0609 8 12C8 10.9391 8.42143 9.92172 9.17157 9.17157C9.92172 8.42143 10.9391 8 12 8C13.0609 8 14.0783 8.42143 14.8284 9.17157C15.5786 9.92172 16 10.9391 16 12C16 13.0609 15.5786 14.0783 14.8284 14.8284C14.0783 15.5786 13.0609 16 12 16ZM12 14C12.5304 14 13.0391 13.7893 13.4142 13.4142C13.7893 13.0391 14 12.5304 14 12C14 11.4696 13.7893 10.9609 13.4142 10.5858C13.0391 10.2107 12.5304 10 12 10C11.4696 10 10.9609 10.2107 10.5858 10.5858C10.2107 10.9609 10 11.4696 10 12C10 12.5304 10.2107 13.0391 10.5858 13.4142C10.9609 13.7893 11.4696 14 12 14Z"
// 			fill="url(#paint0_linear)"/>
// 		<defs>
// 			<linearGradient id="paint0_linear" x1="2.5" y1="1" x2="18.1957" y2="-1.40974" gradientUnits="userSpaceOnUse">
// 				<stop stopColor="#23728E"/>
// 				<stop offset="1" stopColor="#001B31"/>
// 			</linearGradient>
// 		</defs>
// 	</svg>
// )

const iconPortalUpload = (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M7 17.5996H17V18.9996H7V17.5996Z" fill="url(#paint0_linear)" />
    <path
      d="M12.7137 16.2002L12.7137 7.68119L15.278 10.1872L16.2852 9.2002L11.9994 5.0002L7.71373 9.20019L8.72087 10.1872L11.2852 7.68119L11.2852 16.2002L12.7137 16.2002Z"
      fill="url(#paint1_linear)"
    />
    <path d="M7 17.5996H17V18.9996H7V17.5996Z" fill="url(#paint2_linear)" />
    <path
      d="M12.7176 16.2002L12.7176 7.68119L15.2819 10.1872L16.2891 9.2002L12.0033 5.0002L7.71763 9.20019L8.72478 10.1872L11.2891 7.68119L11.2891 16.2002L12.7176 16.2002Z"
      fill="url(#paint3_linear)"
    />
    <path d="M7 17.5996H17V18.9996H7V17.5996Z" fill="url(#paint4_linear)" />
    <path
      d="M12.7176 16.2002L12.7176 7.68119L15.2819 10.1872L16.2891 9.2002L12.0033 5.0002L7.71763 9.20019L8.72478 10.1872L11.2891 7.68119L11.2891 16.2002L12.7176 16.2002Z"
      fill="url(#paint5_linear)"
    />
    <defs>
      <linearGradient
        id="paint0_linear"
        x1="7"
        y1="17.5996"
        x2="10.2368"
        y2="13.4896"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#23728E" />
        <stop offset="1" stopColor="#001B31" />
      </linearGradient>
      <linearGradient
        id="paint1_linear"
        x1="16.2852"
        y1="16.2002"
        x2="9.16919"
        y2="17.1683"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#23728E" />
        <stop offset="1" stopColor="#001B31" />
      </linearGradient>
      <linearGradient
        id="paint2_linear"
        x1="7"
        y1="17.5996"
        x2="10.2368"
        y2="13.4896"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#23728E" />
        <stop offset="1" stopColor="#001B31" />
      </linearGradient>
      <linearGradient
        id="paint3_linear"
        x1="16.2891"
        y1="16.2002"
        x2="9.17309"
        y2="17.1683"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#23728E" />
        <stop offset="1" stopColor="#001B31" />
      </linearGradient>
      <linearGradient
        id="paint4_linear"
        x1="7"
        y1="17.5996"
        x2="10.2368"
        y2="13.4896"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#23728E" />
        <stop offset="1" stopColor="#001B31" />
      </linearGradient>
      <linearGradient
        id="paint5_linear"
        x1="16.2891"
        y1="16.2002"
        x2="9.17309"
        y2="17.1683"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#23728E" />
        <stop offset="1" stopColor="#001B31" />
      </linearGradient>
    </defs>
  </svg>
);

const iconPortalFilter = (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13 12C11.1362 12 9.57006 10.7252 9.12602 9H2V7H9.12602C9.57006 5.27477 11.1362 4 13 4C15.2091 4 17 5.79086 17 8C17 10.2091 15.2091 12 13 12ZM15 8C15 9.10457 14.1046 10 13 10C11.8954 10 11 9.10457 11 8C11 6.89543 11.8954 6 13 6C14.1046 6 15 6.89543 15 8Z"
      fill="url(#paint0_linear)"
    />
    <path d="M18 7H22V9H18V7Z" fill="url(#paint1_linear)" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8 20C6.13616 20 4.57006 18.7252 4.12602 17H2V15H4.12602C4.57006 13.2748 6.13616 12 8 12C10.2091 12 12 13.7909 12 16C12 18.2091 10.2091 20 8 20ZM10 16C10 17.1046 9.10457 18 8 18C6.89543 18 6 17.1046 6 16C6 14.8954 6.89543 14 8 14C9.10457 14 10 14.8954 10 16Z"
      fill="url(#paint2_linear)"
    />
    <path d="M13 17H22V15H13V17Z" fill="url(#paint3_linear)" />
    <defs>
      <linearGradient
        id="paint0_linear"
        x1="2"
        y1="4"
        x2="18.1155"
        y2="0.418942"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#23728E" />
        <stop offset="1" stopColor="#001B31" />
      </linearGradient>
      <linearGradient
        id="paint1_linear"
        x1="2"
        y1="4"
        x2="18.1155"
        y2="0.418942"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#23728E" />
        <stop offset="1" stopColor="#001B31" />
      </linearGradient>
      <linearGradient
        id="paint2_linear"
        x1="2"
        y1="4"
        x2="18.1155"
        y2="0.418942"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#23728E" />
        <stop offset="1" stopColor="#001B31" />
      </linearGradient>
      <linearGradient
        id="paint3_linear"
        x1="2"
        y1="4"
        x2="18.1155"
        y2="0.418942"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#23728E" />
        <stop offset="1" stopColor="#001B31" />
      </linearGradient>
    </defs>
  </svg>
);

const iconPortalClock = (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12 2C6.486 2 2 6.486 2 12C2 17.514 6.486 22 12 22C17.514 22 22 17.514 22 12C22 6.486 17.514 2 12 2ZM12 20C7.589 20 4 16.411 4 12C4 7.589 7.589 4 12 4C16.411 4 20 7.589 20 12C20 16.411 16.411 20 12 20Z"
      fill="url(#paint0_linear)"
    />
    <path d="M13 7H11V13H17V11H13V7Z" fill="url(#paint1_linear)" />
    <defs>
      <linearGradient
        id="paint0_linear"
        x1="2"
        y1="2"
        x2="18.3932"
        y2="-0.914212"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#23728E" />
        <stop offset="1" stopColor="#001B31" />
      </linearGradient>
      <linearGradient
        id="paint1_linear"
        x1="2"
        y1="2"
        x2="18.3932"
        y2="-0.914212"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#23728E" />
        <stop offset="1" stopColor="#001B31" />
      </linearGradient>
    </defs>
  </svg>
);

const iconPublisher = (
  <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
    >
      <path
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M4 22h16a2 2 0 002-2V4a2 2 0 00-2-2H8a2 2 0 00-2 2v16a2 2 0 01-2 2zm0 0a2 2 0 01-2-2v-9c0-1.1.9-2 2-2h2M18 14h-8M15 18h-5"
      ></path>
      <path
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M10 6h8v4h-8V6z"
      ></path>
    </svg>
);

export {
  iconAccordionClose,
  iconAccordionOpen,
  iconArrowThin,
  iconArrowThinLeft,
  iconArrowThinLongLeft,
  iconArrowThinLongRight,
  iconArrowThinLongDown,
  iconBarHorizontal,
  iconChevronDown,
  iconChevronLeft,
  iconChevronRight,
  iconCloseSmall,
  iconCross,
  iconCrossThin,
  iconDot,
  iconElipse,
  iconFacebook,
  iconImage,
  iconLinkedin,
  iconLocationSmall,
  iconLocationAlt,
  iconLoudSpeaker,
  iconMan,
  iconMap,
  iconMenu,
  iconMinus,
  iconMobilePhone,
  iconPasswordHide,
  iconPasswordShow,
  iconPhone,
  iconPlus,
  iconQuote,
  iconSaveAlt,
  iconSearch,
  iconTeam,
  iconTick,
  iconTwitter,
  iconYoutube,
  iconDocumentBlank,
  iconMail,
  iconSettings,
  iconLaptop,
  // Functional Icons with embed gradients
  iconPortalCalendar,
  iconPortalEvent,
  iconPortalLayers,
  iconPortalPhone,
  iconPortalMobile,
  iconPortalMail,
  iconPortalLocation,
  iconPortalMoney,
  // iconPortalDownloadCloud,
  iconPortalSurvey,
  iconPortalVideocam,
  // iconPortalFirst,
  // iconPortalSecond,
  // iconPortalThird,
  iconPortalDownload,
  iconPortalEdit,
  iconPortalUser,
  // iconPortalDepartment,
  iconPortalCopyFile,
  iconPortalJob,
  iconPortalComment,
  // iconPortalAbn,
  iconPortalUpload,
  iconPortalFilter,
  iconPortalClock,
  iconPublisher
};
