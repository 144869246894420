import React from 'react';
import { isEmpty } from 'lodash';
import { PortalPageMenuContent, PortalPageMenuItem } from '@/components/Portal';
import { TrainingCard, ContactCard } from '@/components/Portal/Card';

export default function TrainingModuleMenu({trainingModule, mobile, desktop}) {
  return isEmpty(trainingModule)
  ? <PortalPageMenuContent mobile={mobile} desktop={desktop} /> 
  : <PortalPageMenuContent 
      mobile={mobile} 
      desktop={desktop}
      mobileToggleName="Key Contacts"
    > 
    {desktop && 
      <PortalPageMenuItem>
        <TrainingCard
          size='small'
          description={trainingModule.description}
          id={trainingModule.id}
          key={trainingModule.id}
          pricing={trainingModule.current_users_training_price}
          purchaseStatus={trainingModule.purchaseStatus}
          slug={trainingModule.slug}
          title={trainingModule.name}
          updatedOn={trainingModule.course_updated}
          installation_instructions_data={trainingModule.installation_instructions_data}
          training_downloads_data={trainingModule.training_downloads_data}
        />
      </PortalPageMenuItem>
    }
    { !isEmpty(trainingModule?.key_contacts) &&
      <PortalPageMenuItem title='Key Contacts'>
        <div className="menu-card-wrapper">
          {trainingModule.key_contacts.map(({key, attributes}) => {
            return <ContactCard
              key={key}
              name={attributes?.contact_name}
              title={attributes?.contact_title}
              email={attributes?.contact_email}
              phone={attributes?.contact_phone}
            />
          })}
        </div>
      </PortalPageMenuItem>
      }
    </PortalPageMenuContent>	
}
