import PropTypes from 'prop-types';
import React from 'react';
import ReactModal from 'react-modal';
import { Title, ActionCard,  } from '@/components/'; //Button
import { iconCloseSmall, iconPortalDownload } from '@/images/icons';

const downloadLink = (downloads, useLMS) => {
  let link = false
  const download = downloads.find(link => link.lms_version === useLMS);
  if (download?.download_file) {
    link = download?.download_file?.url
  }
  return link
}

const DownloadModal = ({
  showModal,
  onClose,
  icon,
	subHeading,
	title,
  installation,
  downloads
}) => {
  DownloadModal.propTypes = {
    showModal: PropTypes.bool,
    icon: PropTypes.node,
    subHeading: PropTypes.string,
    title: PropTypes.string,
    installation: PropTypes.array,
    downloads: PropTypes.array
  };

  const linkWeb = downloadLink(downloads, false)
  const LinkLMS = downloadLink(downloads, true)



  return (
    <ReactModal
      isOpen={showModal}
      onRequestClose={onClose}
      contentLabel="Download Training Resources"
      className='portal-modal'
      overlayClassName='portal-overlay'
    >
      <div className="close">
        <div className="icon" onClick={onClose}>{iconCloseSmall}</div>
      </div>

      <div className="modal-card training-download">
        <Title
          icon={icon}
          subHeading={subHeading}
          title={title}
        />

            {installation.map(
              ({installation_instructions}, index) => { return (
                <div className="modal-section" key={index}>
                {!!installation_instructions &&
                <>
                 <p className="small bold">
                     1. Download and read the instructions on how to use your training modules
                </p>
                    <div className="card-collection" >
                    <ActionCard
                        key={index}
                        action='download'
                        title={installation_instructions.title}
                        value={installation_instructions.size}
                        link={installation_instructions.url}
                     />
                    </div>
                </>
            }
            </div>
              )}
            )}

        <div className="modal-section">
          <p className="small bold">
           2. Download your module
          </p>
          <div className="btn-group">
            <a className={`btn btn-fill ${!linkWeb ? 'btn-fill-disabled' : ''}`}
              href={!!linkWeb ? linkWeb : undefined}
              download>
              Web Version <span className="icon">{ iconPortalDownload }</span>
            </a>
            <a className={`btn btn-fill ${!LinkLMS ? 'btn-fill-disabled' : ''}`}
              href={!!LinkLMS ? LinkLMS : undefined}
              download>
              LMS Version <span className="icon">{ iconPortalDownload }</span>
            </a>
          </div>
        </div>
      </div>
    </ReactModal>
  )
}

export default DownloadModal
