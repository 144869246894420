import React, { useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import useMedia from 'use-media';
import usePrevious from '@react-hook/previous';
import { PortalHeaderContext } from '@/context';
// Store
import { useDispatch, useSelector } from 'react-redux';
import { logoutUser, requestHeader } from '@/store/actions';
import { getUser } from '@/store/selectors';
// Components
import { logoCobaText } from '@/images/logos';
import { Motion } from '@/components/common';
import {
  GlobalSearchMobile,
  GlobalSearchPanel,
  Navbar,
  PortalNav,
  PortalNavMobile,
  PortalNavMobileToggle
} from '@/components/Portal';
import AnnouncementBar from '../../Portal/AnnouncementBar';
import { PORTAL_PATHS } from '@/config';

export default function PortalHeader() {
  const user = useSelector(getUser);
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const prevPathname = usePrevious(pathname);
  const isMobile = useMedia('(max-width: 1023px)');

  const [isOpenSearchDesktop, setIsOpenSearchDesktop] = React.useState(false);
  const [isOpenMobileNav, setIsOpenMobileNav] = React.useState(false);
  const [selectedMenuData, setSelectedMenuData] = React.useState([]);
  const [selectedMenuTitle, setSelectedMenuTitle] = React.useState('');
  const onClickOpenMobileNav = (ev) => {
    ev.preventDefault();

    setIsOpenMobileNav(!isOpenMobileNav);
  };
  const handleMobileCss = () => {
    const html = document.getElementsByTagName('html')[0];
    if (isOpenMobileNav) {
      html.classList.add('overflow-hidden');
    } else {
      html.classList.remove('overflow-hidden');
    }
  };

  useEffect(() => {
    dispatch(requestHeader());
  }, []);
  useEffect(() => handleMobileCss(), [isOpenMobileNav]);

  const onClickDropdownMenu = (ev) => {
    if (ev.target.tagName !== 'BUTTON' && ev.target.tagName !== 'A') {
      !!ev && ev.preventDefault();
    }

    const target = ev.currentTarget;
    const targetName = target.dataset ? target.dataset.title : '';
    if (selectedMenuTitle === '') {
      setSelectedMenuTitle(targetName);
    } else {
      setSelectedMenuTitle('');
    }
  };

  React.useEffect(() => {
    if (!!prevPathname && prevPathname !== pathname) {
      setSelectedMenuTitle('');
      setSelectedMenuData([]);
    }
  }, [pathname]);
  React.useEffect(() => {
    if (isMobile && isOpenSearchDesktop) {
      setIsOpenSearchDesktop(false);
    }
  }, [isMobile]);

  // React.useEffect(() => {
  //   if (!!selectedMenuTitle && portalMenuNav[selectedMenuTitle].subMenus) {
  //     setSelectedMenuData(portalMenuNav[selectedMenuTitle].subMenus);
  //   }

  //   if (prevSelectedMenuTitle === selectedMenuTitle) {
  //     setSelectedMenuData([]);
  //   }
  // }, [selectedMenuTitle]);

  const animationConfig = {
    initial: {
      position: 'absolute',
      width: '100%',
      zIndex: 2,
      pointerEvents: 'none',
      opacity: 0,
      translateY: '-100%'
    },
    in: {
      pointerEvents: 'auto',
      opacity: 1,
      translateY: 0,
      transition: {
        ease: 'easeOut',
        translateY: {
          duration: 0.6
        },
        opacity: {
          duration: 0.4,
          delay: 0.2
        }
      }
    },
    out: {
      opacity: 0,
      translateY: '-100%',
      transition: {
        ease: 'easeIn',
        translateY: {
          delay: 0.1,
          duration: 0.3
        },
        opacity: {
          duration: 0.3
        }
      }
    }
  };

  const searchLink = !user.training_only
    ? {
        url: '#',
        text: 'Search',
        onClickItem: (ev) => {
          ev.preventDefault();
          setIsOpenSearchDesktop(!isOpenSearchDesktop);
        }
      }
    : {};
  const navbarLinks = [
    searchLink,
    {
      url: PORTAL_PATHS.myAccount.settings.details,
      text: 'My Account'
    },
    {
      url: '#',
      text: 'Logout',
      onClickItem: (ev) => {
        ev.preventDefault();
        dispatch(logoutUser());
      }
    }
  ];

  return (
    <PortalHeaderContext.Provider
      value={{
        isOpenMobileNav,
        setIsOpenMobileNav,
        onClickDropdownMenu,
        selectedMenuData,
        setSelectedMenuData,
        selectedMenuTitle,
        setSelectedMenuTitle
      }}
    >
      <header className={`portal-header ${isOpenMobileNav ? 'isOpenMobileNav' : ''}`}>
        <AnnouncementBar />
        <Navbar variant="navbar-top" attribution="Member Portal" links={navbarLinks} />

        <div className="container">
          <div className="content">
            <div className="logo">
              <Link to={PORTAL_PATHS.home}>{logoCobaText}</Link>
            </div>

            {isMobile && (
              <PortalNavMobileToggle
                onClick={onClickOpenMobileNav}
                isOpenMobileNav={isOpenMobileNav}
              />
            )}

            {!isMobile && <PortalNav />}
          </div>
        </div>

        {!isMobile && <GlobalSearchPanel isOpen={isOpenSearchDesktop} />}

        {isMobile && (
          <Motion animate={isOpenMobileNav} animationConfig={animationConfig}>
            <div className="portal-nav-mobile">
              <GlobalSearchMobile />
              <PortalNavMobile isOpenMobileNav={isOpenMobileNav} />
            </div>
          </Motion>
        )}
      </header>
    </PortalHeaderContext.Provider>
  );
}
