/**
 * Creates a action, success, failed action mapping in title case
 *
 * @param {string} actionType
 * @param {array} list
 * @returns {object}
 */
const createActions = (actionType, list) => {
  const states = [null, 'SUCCESS', 'FAILED'];

  let actions = {};

  list.forEach((task) => {
    task = task.toUpperCase();
    task = actionType ? `${actionType.toUpperCase()}_${task}` : task;
    states.forEach((state) => {
      const taskState = state ? `${task}_${state}` : task;
      actions[taskState] = taskState;
    });
  });

  return actions;
};

const CREATE_ACCOUNT_FORM = {
  STEP_1: 'STEP_1',
  STEP_2: 'STEP_2',
  STEP_3: 'STEP_3',
  CLEAR_FORM: 'CLEAR_FORM'
};

const USER = {
  REGISTER_USER: 'REGISTER_USER',
  REGISTER_USER_SUCCESS: 'REGISTER_USER_SUCCESS',
  REGISTER_USER_FAILED: 'REGISTER_USER_FAILED',

  LOGIN_USER: 'LOGIN_USER',
  LOGIN_USER_SUCCESS: 'LOGIN_USER_SUCCESS',
  LOGIN_USER_FAILED: 'LOGIN_USER_FAILED',

  LOGOUT_USER: 'LOGOUT_USER',
  LOGOUT_USER_SUCCESS: 'LOGOUT_USER_SUCCESS',
  LOGOUT_USER_FAILED: 'LOGOUT_USER_FAILED',

  CHECK_TOKEN: 'CHECK_TOKEN',
  CHECK_TOKEN_SUCCESS: 'CHECK_TOKEN_SUCCESS',
  CHECK_TOKEN_FAILED: 'CHECK_TOKEN_FAILED',

  FORGOT_PASSWORD: 'FORGOT_PASSWORD',
  FORGOT_PASSWORD_SUCCESS: 'FORGOT_PASSWORD_SUCCESS',
  FORGOT_PASSWORD_FAILED: 'FORGOT_PASSWORD_FAILED',

  RESET_PASSWORD: 'RESET_PASSWORD',
  RESET_PASSWORD_SUCCESS: 'RESET_PASSWORD_SUCCESS',
  RESET_PASSWORD_FAILED: 'RESET_PASSWORD_FAILED',

  UPDATE_ACCOUNT_PASSWORD: 'UPDATE_ACCOUNT_PASSWORD',
  UPDATE_ACCOUNT_PASSWORD_SUCCESS: 'UPDATE_ACCOUNT_PASSWORD_SUCCESS',
  UPDATE_ACCOUNT_PASSWORD_FAILED: 'UPDATE_ACCOUNT_PASSWORD_FAILED',

  UPDATE_ACCOUNT_DETAILS: 'UPDATE_ACCOUNT_DETAILS',
  UPDATE_ACCOUNT_DETAILS_SUCCESS: 'UPDATE_ACCOUNT_DETAILS_SUCCESS',
  UPDATE_ACCOUNT_DETAILS_FAILED: 'UPDATE_ACCOUNT_DETAILS_FAILED',

  REQUEST_USER_DIVISIONS: 'REQUEST_USER_DIVISIONS',
  REQUEST_USER_DIVISIONS_SUCCESS: 'REQUEST_USER_DIVISIONS_SUCCESS',
  REQUEST_USER_DIVISIONS_FAILED: 'REQUEST_USER_DIVISIONS_FAILED',

  REQUEST_BRANCHES: 'REQUEST_BRANCHES',
  REQUEST_BRANCHES_SUCCESS: 'REQUEST_BRANCHES_SUCCESS',
  REQUEST_BRANCHES_FAILED: 'REQUEST_BRANCHES_FAILED',

  UPDATE_BRANCHES: 'UPDATE_BRANCHES',
  UPDATE_BRANCHES_SUCCESS: 'UPDATE_BRANCHES_SUCCESS',
  UPDATE_BRANCHES_FAILED: 'UPDATE_BRANCHES_FAILED',

  UPDATE_DIVISION: 'UPDATE_DIVISION',
  UPDATE_DIVISION_SUCCESS: 'UPDATE_DIVISION_SUCCESS',
  UPDATE_DIVISION_FAILED: 'UPDATE_DIVISION_FAILED',

  UPDATE_INSTITUTION: 'UPDATE_INSTITUTION',
  UPDATE_INSTITUTION_SUCCESS: 'UPDATE_INSTITUTION_SUCCESS',
  UPDATE_INSTITUTION_FAILED: 'UPDATE_INSTITUTION_FAILED',

  CREATE_NEW_BRANCH: 'CREATE_NEW_BRANCH',
  CREATE_NEW_BRANCH_SUCCESS: 'CREATE_NEW_BRANCH_SUCCESS',
  CREATE_NEW_BRANCH_FAILED: 'CREATE_NEW_BRANCH_FAILED',

  CREATE_NEW_DIVISION: 'CREATE_NEW_DIVISION',
  CREATE_NEW_DIVISION_SUCCESS: 'CREATE_NEW_DIVISION_SUCCESS',
  CREATE_NEW_DIVISION_FAILED: 'CREATE_NEW_DIVISION_FAILED'
};

const EMAIL_SUBSCRIPTIONS = {
  REQUEST_EMAIL_SUBSCRIPTIONS: 'REQUEST_EMAIL_SUBSCRIPTIONS',
  REQUEST_EMAIL_SUBSCRIPTIONS_SUCCESS: 'REQUEST_EMAIL_SUBSCRIPTIONS_SUCCESS',
  REQUEST_EMAIL_SUBSCRIPTIONS_FAILED: 'REQUEST_EMAIL_SUBSCRIPTIONS_FAILED',
  UPDATE_EMAIL_SUBSCRIPTIONS: 'UPDATE_EMAIL_SUBSCRIPTIONS',
  UPDATE_EMAIL_SUBSCRIPTIONS_SUCCESS: 'UPDATE_EMAIL_SUBSCRIPTIONS_SUCCESS',
  UPDATE_EMAIL_SUBSCRIPTIONS_FAILED: 'UPDATE_EMAIL_SUBSCRIPTIONS_FAILED'
};

const NEWS_ARTICLES = {
  REQUEST_NEWS_ARTICLES_FIRST_PAGE: 'REQUEST_NEWS_ARTICLES_FIRST_PAGE',
  REQUEST_NEWS_ARTICLES_FIRST_PAGE_SUCCESS: 'REQUEST_NEWS_ARTICLES_FIRST_PAGE_SUCCESS',
  REQUEST_NEWS_ARTICLES_FIRST_PAGE_FAILED: 'REQUEST_NEWS_ARTICLES_FIRST_PAGE_FAILED',

  REQUEST_NEWS_ARTICLES_NEXT_PAGE: 'REQUEST_NEWS_ARTICLES_NEXT_PAGE',
  REQUEST_NEWS_ARTICLES_NEXT_PAGE_SUCCESS: 'REQUEST_NEWS_ARTICLES_NEXT_PAGE_SUCCESS',
  REQUEST_NEWS_ARTICLES_NEXT_PAGE_FAILED: 'REQUEST_NEWS_ARTICLES_NEXT_PAGE_FAILED',

  REQUEST_NEWS_ARTICLE_BY_ID: 'REQUEST_NEWS_ARTICLE_BY_ID',
  REQUEST_NEWS_ARTICLE_BY_ID_SUCCESS: 'REQUEST_NEWS_ARTICLE_BY_ID_SUCCESS',
  REQUEST_NEWS_ARTICLE_BY_ID_FAILED: 'REQUEST_NEWS_ARTICLE_BY_ID_FAILED',

  REQUEST_NEWS_ARTICLE_BY_SLUG: 'REQUEST_NEWS_ARTICLE_BY_SLUG',
  REQUEST_NEWS_ARTICLE_BY_SLUG_SUCCESS: 'REQUEST_NEWS_ARTICLE_BY_SLUG_SUCCESS',
  REQUEST_NEWS_ARTICLE_BY_SLUG_FAILED: 'REQUEST_NEWS_ARTICLE_BY_SLUG_FAILED',

  REQUEST_NEWS_TOPICS: 'REQUEST_NEWS_TOPICS',
  REQUEST_NEWS_TOPICS_SUCCESS: 'REQUEST_NEWS_TOPICS_SUCCESS',
  REQUEST_NEWS_TOPICS_FAILED: 'REQUEST_NEWS_TOPICS_FAILED',

  REQUEST_REGULATORS: 'REQUEST_REGULATORS',
  REQUEST_REGULATORS_SUCCESS: 'REQUEST_REGULATORS_SUCCESS',
  REQUEST_REGULATORS_FAILED: 'REQUEST_REGULATORS_FAILED',

  REQUEST_POLICY_STAGE: 'REQUEST_POLICY_STAGE',
  REQUEST_POLICY_STAGE_SUCCESS: 'REQUEST_POLICY_STAGE_SUCCESS',
  REQUEST_POLICY_STAGE_FAILED: 'REQUEST_POLICY_STAGE_FAILED',

  CLEAR_FILTERS: 'CLEAR_FILTERS',

  SELECT_NEWS_TOPICS: 'SELECT_NEWS_TOPICS',

  SUBMIT_FEEDBACK: 'SUBMIT_FEEDBACK',
  SUBMIT_FEEDBACK_SUCCESS: 'SUBMIT_FEEDBACK_SUCCESS',
  SUBMIT_FEEDBACK_FAILED: 'SUBMIT_FEEDBACK_FAILED',
  REQUEST_NEWS_ARTICLE_BY_BULLETIN_ID: 'REQUEST_NEWS_ARTICLE_BY_BULLETIN_ID',
  REQUEST_NEWS_ARTICLE_BY_BULLETIN_ID_SUCCESS:
    'REQUEST_NEWS_ARTICLE_BY_BULLETIN_ID_SUCCESS',
  REQUEST_NEWS_ARTICLE_BY_BULLETIN_ID_FAILED: 'REQUEST_NEWS_ARTICLE_BY_BULLETIN_ID_FAILED'
};

const FEEDS = {
  REQUEST_FEEDS_BY_PAGE: 'REQUEST_FEEDS_BY_PAGE',
  REQUEST_FEEDS_BY_PAGE_SUCCESS: 'REQUEST_FEEDS_BY_PAGE_SUCCESS',
  REQUEST_FEEDS_BY_PAGE_FAILED: 'REQUEST_FEEDS_BY_PAGE_FAILED'
};

const ARTICLES = {
  // article
  REQUEST_ARTICLE: 'REQUEST_ARTICLE',
  RECEIVED_ARTICLE: 'RECEIVED_ARTICLE',
  FAILED_ARTICLE: 'FAILED_ARTICLE',
  // article by slug
  REQUEST_ARTICLE_BY_SLUG: 'REQUEST_ARTICLE_BY_SLUG',
  RECEIVED_ARTICLE_BY_SLUG: 'RECEIVED_ARTICLE_BY_SLUG',
  FAILED_ARTICLE_BY_SLUG: 'FAILED_ARTICLE_BY_SLUG',
  // article footer articles
  REQUEST_FOOTER_ARTICLES: 'REQUEST_FOOTER_ARTICLES',
  RECEIVED_FOOTER_ARTICLES: 'RECEIVED_FOOTER_ARTICLES',
  FAILED_FOOTER_ARTICLES: 'FAILED_FOOTER_ARTICLES',
  // articles
  REQUEST_ARTICLES_BY_CATEGORY_TYPE: 'REQUEST_ARTICLES_BY_CATEGORY_TYPE',
  RECEIVED_ARTICLES_BY_CATEGORY_TYPE: 'RECEIVED_ARTICLES_BY_CATEGORY_TYPE',
  FAILED_ARTICLES_BY_CATEGORY_TYPE: 'FAILED_ARTICLES_BY_CATEGORY_TYPE',
  //article types
  REQUEST_ARTICLE_TYPES: 'REQUEST_ARTICLE_TYPES',
  RECEIVED_ARTICLE_TYPES: 'RECEIVED_ARTICLE_TYPES',
  FAILED_ARTICLE_TYPES: 'FAILED_ARTICLE_TYPES',
  //banking tips
  REQUEST_BANKING_TIPS: 'REQUEST_BANKING_TIPS',
  RECEIVED_BANKING_TIPS: 'RECEIVED_BANKING_TIPS',
  FAILED_BANKING_TIPS: 'FAILED_BANKING_TIPS',
  //campaigns
  REQUEST_CAMPAIGNS: 'REQUEST_CAMPAIGNS',
  RECEIVED_CAMPAIGNS: 'RECEIVED_CAMPAIGNS',
  FAILED_CAMPAIGNS: 'FAILED_CAMPAIGNS',
  //customer stories
  REQUEST_CUSTOMER_STORIES: 'REQUEST_CUSTOMER_STORIES',
  RECEIVED_CUSTOMER_STORIES: 'RECEIVED_CUSTOMER_STORIES',
  FAILED_CUSTOMER_STORIES: 'FAILED_CUSTOMER_STORIES',
  //sector news
  REQUEST_SECTOR_NEWS: 'REQUEST_SECTOR_NEWS',
  RECEIVED_SECTOR_NEWS: 'RECEIVED_SECTOR_NEWS',
  FAILED_SECTOR_NEWS: 'FAILED_SECTOR_NEWS',
  //general articles
  REQUEST_GENERAL_ARTICLES: 'REQUEST_GENERAL_ARTICLES',
  RECEIVED_GENERAL_ARTICLES: 'RECEIVED_GENERAL_ARTICLES',
  FAILED_GENERAL_ARTICLES: 'FAILED_GENERAL_ARTICLES',
  //how it works articles
  REQUEST_HOW_IT_WORKS_ARTICLES: 'REQUEST_HOW_IT_WORKS_ARTICLES',
  RECEIVED_HOW_IT_WORKS_ARTICLES: 'RECEIVED_HOW_IT_WORKS_ARTICLES',
  FAILED_HOW_IT_WORKS_ARTICLES: 'FAILED_HOW_IT_WORKS_ARTICLES'
};

const COORDINATES = {
  REQUEST_COORDINATES: 'REQUEST_COORDINATES',
  RECEIVED_COORDINATES: 'RECEIVED_COORDINATES',
  FAILED_COORDINATES: 'FAILED_COORDINATES',
  CLEAR_COORDINATES: 'CLEAR_COORDINATES'
};

const CONTACT_PAGE = {
  REQUEST_CONTACT_PAGE: 'REQUEST_CONTACT_PAGE',
  RECEIVED_CONTACT_PAGE: 'RECEIVED_CONTACT_PAGE',
  FAILED_CONTACT_PAGE: 'FAILED_CONTACT_PAGE'
};

const CONTENT = {
  REQUEST_CONTENT: 'REQUEST_CONTENT',
  RECEIVED_CONTENT: 'RECEIVED_CONTENT',
  FAILED_CONTENT: 'FAILED_CONTENT'
};

const CUSTOMER_SUPPORT = {
  REQUEST_CUSTOMER_SUPPORT: 'REQUEST_CUSTOMER_SUPPORT',
  RECEIVED_CUSTOMER_SUPPORT: 'RECEIVED_CUSTOMER_SUPPORT',
  FAILED_CUSTOMER_SUPPORT: 'FAILED_CUSTOMER_SUPPORT'
};

const GEOLOCATION = {
  REQUEST_GEOLOCATION: 'REQUEST_GEOLOCATION',
  RECEIVED_GEOLOCATION: 'RECEIVED_GEOLOCATION',
  FAILED_GEOLOCATION: 'FAILED_GEOLOCATION'
};

const HOME_PAGE = {
  REQUEST_HOME_PAGE: 'REQUEST_HOME_PAGE',
  REQUEST_HOME_PAGE_SUCCESS: 'RECEIVED_HOME_PAGE_SUCCESS',
  REQUEST_HOME_PAGE_FAILED: 'FAILED_REQUEST_FAILED'
};

const NAV = {
  REQUEST_QUICKLINKS: 'REQUEST_QUICKLINKS',
  REQUEST_QUICKLINKS_SUCCESS: 'REQUEST_QUICKLINKS_SUCCESS',
  REQUEST_QUICKLINKS_FAILED: 'REQUEST_QUICKLINKS_FAILED'
};

const CEO = {
  REQUEST_CEO_NEWS_ARTICLE_LATEST: 'REQUEST_CEO_NEWS_ARTICLE_LATEST',
  REQUEST_CEO_NEWS_ARTICLE_LATEST_SUCCESS: 'REQUEST_CEO_NEWS_ARTICLE_LATEST_SUCCESS',
  REQUEST_CEO_NEWS_ARTICLE_LATEST_FAILED: 'REQUEST_CEO_NEWS_ARTICLE_LATEST_FAILED',

  REQUEST_CEO_NEWS_ARTICLE_BY_SLUG: 'REQUEST_CEO_NEWS_ARTICLE_BY_SLUG',
  REQUEST_CEO_NEWS_ARTICLE_BY_SLUG_SUCCESS: 'REQUEST_CEO_NEWS_ARTICLE_BY_SLUG_SUCCESS',
  REQUEST_CEO_NEWS_ARTICLE_BY_SLUG_FAILED: 'REQUEST_CEO_NEWS_ARTICLE_BY_SLUG_FAILED'
};

const MEMBER_INSTITUTIONS = {
  REQUEST_MEMBERS_INSTITUTIONS: 'REQUEST_MEMBERS_INSTITUTIONS',
  REQUEST_MEMBERS_INSTITUTIONS_SUCCESS: 'REQUEST_MEMBERS_INSTITUTIONS_SUCCESS',
  REQUEST_MEMBERS_INSTITUTIONS_FAILED: 'REQUEST_MEMBERS_INSTITUTIONS_FAILED',
  REQUEST_DIVISIONS_BANKS: 'REQUEST_DIVISIONS_BANKS',
  REQUEST_DIVISIONS_BANKS_SUCCESS: 'REQUEST_DIVISIONS_BANKS_SUCCESS',
  REQUEST_DIVISIONS_BANKS_FAILED: 'REQUEST_DIVISIONS_BANKS_FAILED',
  REQUEST_BRANCHES_NEAR_ME: 'REQUEST_BRANCHES_NEAR_ME',
  REQUEST_BRANCHES_NEAR_ME_SUCCESS: 'REQUEST_BRANCHES_NEAR_ME_SUCCESS',
  REQUEST_BRANCHES_NEAR_ME_FAILED: 'REQUEST_BRANCHES_NEAR_ME_FAILED',
  CLEAR_BRANCHES_NEAR_ME: 'CLEAR_BRANCHES_NEAR_ME',
  REQUEST_ALL_OFFERS: 'REQUEST_ALL_OFFERS',
  REQUEST_ALL_OFFERS_SUCCESS: 'REQUEST_ALL_OFFERS_SUCCESS',
  REQUEST_ALL_OFFERS_FAILED: 'REQUEST_ALL_OFFERS_FAILED'
};

const JOBS = {
  REQUEST_JOB_VACANCIES_FIRST_PAGE: 'REQUEST_JOB_VACANCIES_FIRST_PAGE',
  REQUEST_JOB_VACANCIES_FIRST_PAGE_SUCCESS: 'REQUEST_JOB_VACANCIES_FIRST_PAGE_SUCCESS',
  REQUEST_JOB_VACANCIES_FIRST_PAGE_FAILED: 'REQUEST_JOB_VACANCIES_FIRST_PAGE_FAILED',
  REQUEST_JOB_VACANCIES_NEXT_PAGE: 'REQUEST_JOB_VACANCIES_NEXT_PAGE',
  REQUEST_JOB_VACANCIES_NEXT_PAGE_SUCCESS: 'REQUEST_JOB_VACANCIES_NEXT_PAGE_SUCCESS',
  REQUEST_JOB_VACANCIES_NEXT_PAGE_FAILED: 'REQUEST_JOB_VACANCIES_NEXT_PAGE_FAILED',

  SUBMIT_JOB_LISTING: 'SUBMIT_JOB_LISTING',
  SUBMIT_JOB_LISTING_SUCCESS: 'SUBMIT_JOB_LISTING_SUCCESS',
  SUBMIT_JOB_LISTING_FAILED: 'SUBMIT_JOB_LISTING_FAILED',

  REQUEST_JOB_DETAILS: 'REQUEST_JOB_DETAILS',
  REQUEST_JOB_DETAILS_SUCCESS: 'REQUEST_JOB_DETAILS_SUCCESS',
  REQUEST_JOB_DETAILS_FAILED: 'REQUEST_JOB_DETAILS_FAILED',
  RESET_JOB_LISTING: 'RESET_JOB_LISTING',
  CLEAR_SEARCH_JOB: 'CLEAR_SEARCH_JOB'
};

const STAFF = {
  REQUEST_STAFF_LIST: 'REQUEST_STAFF_LIST',
  REQUEST_STAFF_LIST_SUCCESS: 'REQUEST_STAFF_LIST_SUCCESS',
  REQUEST_STAFF_LIST_FAILED: 'REQUEST_STAFF_LIST_FAILED'
};

const SUBSCRIPTION = {
  REQUEST_SUBSCRIPTION: 'REQUEST_SUBSCRIPTION',
  SUCCESSFUL_SUBSCRIPTION: 'SUCCESSFUL_SUBSCRIPTION',
  FAILED_SUBSCRIPTION: 'FAILED_SUBSCRIPTION'
};

const EVENTS = {
  ...createActions('request', ['event', 'past_years', 'upcoming']),
  ...createActions('SEARCH', ['events']),
  CLEAR_SEARCH_EVENTS: 'CLEAR_SEARCH_EVENTS'
};

const ANALYTIC_EVENTS = {
  TRACK_EVENT: 'TRACK_EVENT'
};

const MANAGE_USERS = {
  ...createActions('request', ['users', 'user_approve', 'user_delete'])
};

const PAGES = {
  ...createActions('request', ['list', 'by_id', 'by_slug'])
};

const SUPPORT = {
  REQUEST_CONTACT_SUBJECTS: 'REQUEST_CONTACT_SUBJECTS',
  REQUEST_CONTACT_SUBJECTS_SUCCESS: 'REQUEST_CONTACT_SUBJECTS_SUCCESS',
  REQUEST_CONTACT_SUBJECTS_FAILED: 'REQUEST_CONTACT_SUBJECTS_FAILED',
  SUBMIT_CONTACT_FORM: 'SUBMIT_CONTACT_FORM',
  SUBMIT_CONTACT_FORM_SUCCESS: 'SUBMIT_CONTACT_FORM_SUCCESS',
  SUBMIT_CONTACT_FORM_FAILED: 'SUBMIT_CONTACT_FORM_FAILED'
};

const TRAINING = {
  ...createActions('request', [
    'training_categories',
    'training_module_by_id',
    'training_modules'
  ]),
  CLEAR_TRAINING_MODULES: 'CLEAR_TRAINING_MODULES'
};

const TRAINING_CART = {
  ...createActions('submit', ['purchase']),
  ITEM_ADD: 'ITEM_ADD',
  ITEM_REMOVE: 'ITEM_REMOVE'
};

const SEARCH = {
  SEARCH_ALL_BY_TERM: 'SEARCH_ALL_BY_TERM',
  SEARCH_ALL_BY_TERM_SUCCESS: 'SEARCH_ALL_BY_TERM_SUCCESS',
  SEARCH_ALL_BY_TERM_FAILED: 'SEARCH_ALL_BY_TERM_FAILED',
  CLEAR_SEARCH_QUERY: 'CLEAR_SEARCH_QUERY'
};

const ANNOUNCEMENT = {
  REQUEST_ANNOUNCEMENT: 'REQUEST_ANNOUNCEMENT',
  REQUEST_ANNOUNCEMENT_SUCCESS: 'REQUEST_ANNOUNCEMENT_SUCCESS',
  REQUEST_ANNOUNCEMENT_FAILED: 'REQUEST_ANNOUNCEMENT_FAILED'
};

const PORTAL_ANNOUNCEMENT = {
  REQUEST_PORTAL_ANNOUNCEMENT: 'REQUEST_PORTAL_ANNOUNCEMENT',
  REQUEST_PORTAL_ANNOUNCEMENT_SUCCESS: 'REQUEST_PORTAL_ANNOUNCEMENT_SUCCESS',
  REQUEST_PORTAL_ANNOUNCEMENT_FAILED: 'REQUEST_PORTAL_ANNOUNCEMENT_FAILED'
};
const HEADER = {
  REQUEST_HEADER: 'REQUEST_HEADER',
  REQUEST_HEADER_SUCCESS: 'REQUEST_HEADER_SUCCESS',
  REQUEST_HEADER_FAILED: 'REQUEST_HEADER_FAILED'
};
const FOOTER = {
  REQUEST_FOOTER: 'REQUEST_FOOTER',
  REQUEST_FOOTER_SUCCESS: 'REQUEST_FOOTER_SUCCESS',
  REQUEST_FOOTER_FAILED: 'REQUEST_FOOTER_FAILED'
};
export {
  ANALYTIC_EVENTS,
  ANNOUNCEMENT,
  ARTICLES,
  CEO,
  CONTACT_PAGE,
  CONTENT,
  COORDINATES,
  CREATE_ACCOUNT_FORM,
  CUSTOMER_SUPPORT,
  EMAIL_SUBSCRIPTIONS,
  EVENTS,
  FOOTER,
  GEOLOCATION,
  HEADER,
  HOME_PAGE,
  JOBS,
  MANAGE_USERS,
  MEMBER_INSTITUTIONS,
  NAV,
  NEWS_ARTICLES,
  FEEDS,
  PAGES,
  PORTAL_ANNOUNCEMENT,
  SEARCH,
  STAFF,
  SUBSCRIPTION,
  SUPPORT,
  TRAINING,
  TRAINING_CART,
  USER
};
